
import { Component, Vue, Prop } from 'vue-property-decorator';
import { buyingListsData } from '@/mock/buying-lists';
import { SupplierSettings } from '@/models/supplier_settings';

interface IBuyingList {
  name: string;
  createdAt: string;
  status: string;
  totalItems: number;
  totalValue: number;
  lastUpdated: string;
}

interface IProduct {
  id: number;
  name: string;
  sku: string;
  currentStock: number;
  recommendedReorderQuantity: number;
  unitCost: number;
  reorderQuantity: number;
}

interface ITableHeader {
  text: string;
  value: string;
  sortable?: boolean;
}

@Component
export default class ReviewProducts extends Vue {
  @Prop({ required: true }) private readonly supplier!: any;
  @Prop({ required: true }) private readonly products!: IProduct[];

  private activeBuyingLists: IBuyingList[] = buyingListsData.activeBuyingLists;
  private supplierSettings: SupplierSettings = new SupplierSettings();
  private saveInProgress: boolean = false;
  private localProducts: IProduct[] = [];

  private readonly buyingListHeaders: ITableHeader[] = [
    { text: 'Name', value: 'name' },
    { text: 'Created', value: 'createdAt' },
    { text: 'Status', value: 'status' },
    { text: 'Total Items', value: 'totalItems' },
    { text: 'Total Value', value: 'totalValue' },
    { text: 'Last Updated', value: 'lastUpdated' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  private readonly headers: ITableHeader[] = [
    { text: 'Product Name', value: 'name' },
    { text: 'SKU', value: 'sku' },
    { text: 'Current Stock', value: 'currentStock' },
    { text: 'Recommended Reorder Quantity', value: 'recommendedReorderQuantity' },
    { text: 'Unit Cost', value: 'unitCost' },
    { text: 'Reorder Quantity', value: 'reorderQuantity' },
  ];

  private created(): void {
    this.supplierSettings = new SupplierSettings({
      ...this.supplier,
      supplierId: this.supplier.id,
    });
    this.localProducts = [...this.products];
  }

  private selectAllProducts(): void {
    this.localProducts = this.products.map((p: IProduct) => ({
      ...p,
      reorderQuantity: p.recommendedReorderQuantity,
    }));
    this.$emit('next', this.localProducts);
  }

  private getStatusColor(status: string): string {
    const colors: Record<string, string> = {
      'In Progress': 'blue',
      'Draft': 'grey',
      'Pending Review': 'orange',
    };
    return colors[status] || 'grey';
  }

  private formatNumber(value: number): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  private viewBuyingList(buyingList: IBuyingList): void {
    this.$emit('view-buying-list', buyingList);
  }

  private async saveSupplierSettings(): Promise<void> {
    try {
      if (this.saveInProgress) {
        return;
      }
      this.saveInProgress = true;

      if (this.supplierSettings.canSave()) {
        await this.supplierSettings.save();
        this.$emit('settings-updated', this.supplierSettings);
      }
    } catch (error) {
      console.error('Failed to save supplier settings:', error);
    } finally {
      this.saveInProgress = false;
    }
  }
}
