
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IIdentificationMetric } from '@/models/sourcing_strategy';

@Component
export default class IdentificationMetricsBuilder extends Vue {
  @Prop({ type: Array, required: true }) readonly availableMetrics!: Array<{text: string; value: string}>;
  @Prop({ type: Number, default: 5 }) readonly maxMetrics!: number;
  @Prop({ type: Array, required: false, default: () => [] }) readonly value!: IIdentificationMetric[];
  @Prop({ type: Array, required: false, default: () => [] }) readonly initialMetrics!: IIdentificationMetric[];

  private metricRules: IIdentificationMetric[] = [];
  private logicalOperator = 'AND';

  private comparisonTypes = [
    { text: 'Compare to Value', value: 'value' },
    { text: 'Compare to Metric', value: 'metric' }
  ];

  private operators = [
    { text: 'Greater than (>)', value: '>' },
    { text: 'Less than (<)', value: '<' },
    { text: 'Equal to (=)', value: '=' },
    { text: 'Greater than % of', value: '>%' },
    { text: 'Less than % of', value: '<%' },
    { text: 'Equal to % of', value: '=%' }
  ];

  private logicalOperators = [
    { text: 'Match ALL conditions (AND)', value: 'AND' },
    { text: 'Match ANY condition (OR)', value: 'OR' }
  ];

  created() {
    // Initialize with initialMetrics if provided
    if (this.initialMetrics && this.initialMetrics.length > 0) {
      this.metricRules = this.initialMetrics.map(rule => {
        const metric = rule.metric || '';
        const isValidMetric = this.availableMetrics.some(m => m.value === metric);
        
        return {
          metric: isValidMetric ? metric : '',
          comparison: rule.comparison || 'value',
          operator: rule.operator || '',
          threshold: rule.threshold || 0,
          secondMetric: rule.secondMetric
        };
      });
      // Emit initial change
      this.$nextTick(() => {
        this.emitChange();
      });
    } else if (!this.value || this.value.length === 0) {
      // Initialize with default if no value provided
      this.metricRules = [{
        metric: '',
        comparison: 'value',
        operator: '',
        threshold: 0
      }];
    } else {
      // Initialize with provided value
      this.metricRules = this.value.map(rule => {
        const metric = rule.metric || '';
        const isValidMetric = this.availableMetrics.some(m => m.value === metric);
        
        return {
          metric: isValidMetric ? metric : '',
          comparison: rule.comparison || 'value',
          operator: rule.operator || '',
          threshold: rule.threshold || 0,
          secondMetric: rule.secondMetric
        };
      });
    }
  }

  @Watch('initialMetrics', { deep: true })
  onInitialMetricsChange(newValue: IIdentificationMetric[]) {
    if (newValue && newValue.length > 0) {
      this.metricRules = newValue.map(rule => {
        const metric = rule.metric || '';
        const isValidMetric = this.availableMetrics.some(m => m.value === metric);
        
        return {
          metric: isValidMetric ? metric : '',
          comparison: rule.comparison || 'value',
          operator: rule.operator || '',
          threshold: rule.threshold || 0,
          secondMetric: rule.secondMetric
        };
      });
      this.emitChange();
    }
  }

  @Watch('value', { deep: true })
  onValueChange(newValue: IIdentificationMetric[]) {
    // Only update if no initialMetrics and the new value is different
    if (!this.initialMetrics.length && newValue && 
        JSON.stringify(this.metricRules) !== JSON.stringify(newValue)) {
      this.metricRules = newValue.map(rule => ({
        metric: rule.metric || '',
        comparison: rule.comparison || 'value',
        operator: rule.operator || '',
        threshold: rule.threshold || 0,
        secondMetric: rule.secondMetric
      }));
      this.emitChange();
    }
  }

  // Add a watcher for availableMetrics to handle case where metrics load after component
  @Watch('availableMetrics')
  onAvailableMetricsChange() {
    // Revalidate current metrics when available metrics change
    if (this.metricRules.length > 0) {
      this.metricRules = this.metricRules.map(rule => {
        const isValidMetric = this.availableMetrics.some(m => m.value === rule.metric);
        return {
          ...rule,
          metric: isValidMetric ? rule.metric : ''
        };
      });
      this.emitChange();
    }
  }

  private addMetricRule(): void {
    if (this.metricRules.length < this.maxMetrics) {
      this.metricRules.push({
        metric: '',
        comparison: 'value',
        operator: '',
        threshold: 0
      });
      this.emitChange();
    }
  }

  private removeMetricRule(index: number): void {
    if (this.metricRules.length > 1) {
      this.metricRules.splice(index, 1);
      this.emitChange();
    }
  }

  private onComparisonChange(rule: IIdentificationMetric): void {
    if (rule.comparison === 'value') {
      delete rule.secondMetric;
    }
    this.emitChange();
  }

  private getOperators(comparison: string): Array<{text: string; value: string}> {
    if (comparison === 'metric') {
      return this.operators.filter(op => op.value.includes('%'));
    }
    return this.operators.filter(op => !op.value.includes('%'));
  }

  private getThresholdLabel(metricType: string, operator: string): string {
    const metric = this.availableMetrics.find(m => m.value === metricType);
    const unit = metric?.text.match(/\((.*?)\)/)?.[1] || '';
    return `Threshold ${unit ? `(${unit})` : ''}`;
  }

  private emitChange(): void {
    const validRules = this.metricRules.filter(r => r.metric && r.operator);
    this.$emit('input', validRules);
  }

  get conditionsPreview(): string {
    return this.metricRules
      .filter(r => r.metric && r.operator)
      .map(r => {
        const metric1 = this.availableMetrics.find(am => am.value === r.metric);
        if (r.comparison === 'metric' && r.secondMetric) {
          const metric2 = this.availableMetrics.find(am => am.value === r.secondMetric);
          if (r.operator.includes('%')) {
            return `${metric1?.text || r.metric} ${r.operator.charAt(0)} ${r.threshold}% of ${metric2?.text || r.secondMetric}`;
          }
          return `${metric1?.text || r.metric} ${r.operator} ${metric2?.text || r.secondMetric}`;
        }
        return `${metric1?.text || r.metric} ${r.operator} ${r.threshold}`;
      })
      .join(` ${this.logicalOperator} `);
  }
}
