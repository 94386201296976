
import { Component, Vue } from 'vue-property-decorator';
import {
  IBuyingListItem,
  IBuyingList,
  IProduct,
  ISupplier,
  IViewMode,
  ISupplierGroup,
  DateRange
} from '@/models/buying_list';

@Component({
  name: 'BuyingListSuggestion'
})
export default class BuyingListSuggestion extends Vue {
  // Data properties
  private viewMode: IViewMode = 'day';
  private isCalendarOpen = false;
  private expandedGroups: { [key: number]: boolean } = {};
  private selectedDates: string[] = [
    '2025-02-13',
    '2025-02-20'
  ];

  // Extended mock data with more dates and products
  private mockData: { suppliers: ISupplier[] } = {
    suppliers: [
      {
        id: 1,
        name: "Tech Components Ltd",
        buyingCycle: 7,
        products: [
          {
            id: 101,
            name: "USB-C Cables",
            currentStock: 150,
            reorderPoint: 200,
            reorderQuantity: 500,
            leadTime: 14,
            buyingCycle: 7,
            nextReorderDate: "2025-02-13",
            unitPrice: 2.50,
            category: "Electronics"
          },
          {
            id: 102,
            name: "Wireless Chargers",
            currentStock: 80,
            reorderPoint: 100,
            reorderQuantity: 300,
            leadTime: 10,
            buyingCycle: 7,
            nextReorderDate: "2025-02-13",
            unitPrice: 15.99,
            category: "Electronics"
          },
          {
            id: 103,
            name: "Power Adapters",
            currentStock: 120,
            reorderPoint: 150,
            reorderQuantity: 400,
            leadTime: 12,
            buyingCycle: 7,
            nextReorderDate: "2025-02-15",
            unitPrice: 8.99,
            category: "Electronics"
          },
          {
            id: 104,
            name: "HDMI Cables",
            currentStock: 90,
            reorderPoint: 120,
            reorderQuantity: 300,
            leadTime: 10,
            buyingCycle: 7,
            nextReorderDate: "2025-02-17",
            unitPrice: 5.99,
            category: "Electronics"
          }
        ]
      },
      {
        id: 2,
        name: "Global Electronics Supply",
        buyingCycle: 14,
        products: [
          {
            id: 201,
            name: "Power Banks",
            currentStock: 200,
            reorderPoint: 250,
            reorderQuantity: 600,
            leadTime: 21,
            buyingCycle: 14,
            nextReorderDate: "2025-02-14",
            unitPrice: 25.99,
            category: "Electronics"
          },
          {
            id: 202,
            name: "Bluetooth Speakers",
            currentStock: 150,
            reorderPoint: 180,
            reorderQuantity: 400,
            leadTime: 18,
            buyingCycle: 14,
            nextReorderDate: "2025-02-16",
            unitPrice: 35.99,
            category: "Electronics"
          }
        ]
      },
      {
        id: 3,
        name: "Premium Components Inc",
        buyingCycle: 10,
        products: [
          {
            id: 301,
            name: "Gaming Keyboards",
            currentStock: 75,
            reorderPoint: 100,
            reorderQuantity: 200,
            leadTime: 15,
            buyingCycle: 10,
            nextReorderDate: "2025-02-13",
            unitPrice: 89.99,
            category: "Gaming"
          },
          {
            id: 302,
            name: "Gaming Mice",
            currentStock: 90,
            reorderPoint: 120,
            reorderQuantity: 250,
            leadTime: 12,
            buyingCycle: 10,
            nextReorderDate: "2025-02-18",
            unitPrice: 59.99,
            category: "Gaming"
          }
        ]
      }
    ]
  };

  // Computed properties
  get selectedDateRange(): DateRange {
    return {
      from: new Date(this.selectedDates[0]),
      to: new Date(this.selectedDates[1])
    };
  }

  get getFormattedDateRange(): string {
    const from = new Date(this.selectedDates[0]);
    const to = new Date(this.selectedDates[1]);
    return `${this.formatDate(from, 'short')} - ${this.formatDate(to, 'short')}`;
  }

  get datesWithSuggestions(): Date[] {
    const dates: Date[] = [];
    const currentDate = new Date(this.selectedDateRange.from);
    const endDate = new Date(this.selectedDateRange.to);
    
    while (currentDate <= endDate) {
      const periodKey = this.getPeriodKey(currentDate);
      const suggestions = this.getSuggestionsByPeriod();
      if (suggestions[periodKey] && suggestions[periodKey].length > 0) {
        dates.push(new Date(currentDate));
      }
      
      // Move to next period based on view mode
      switch (this.viewMode) {
        case 'week':
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case 'month':
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        default:
          currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    
    return dates;
  }

  // Methods
  private formatDate(date: Date, format: 'short' | 'full' = 'full'): string {
    const options: Intl.DateTimeFormatOptions = format === 'full' 
      ? { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      : { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  private formatDateKey(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private formatCurrency(amount: number): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  }

  private calculateOrderValue(quantity: number, unitPrice: number): string {
    return this.formatCurrency(quantity * unitPrice);
  }

  private calculateTotalOrderValue(products: IProduct[]): string {
    const total = products.reduce((sum, product) => 
      sum + (product.reorderQuantity * product.unitPrice), 0
    );
    return this.formatCurrency(total);
  }

  private getPeriodKey(date: Date): string {
    switch (this.viewMode) {
      case 'week':
        // Get the Monday of the week
        const monday = new Date(date);
        monday.setDate(monday.getDate() - monday.getDay() + 1);
        return this.formatDateKey(monday);
      case 'month':
        // Get the first day of the month
        return date.toISOString().slice(0, 7); // YYYY-MM
      default:
        return this.formatDateKey(date);
    }
  }

  private getPeriodRange(date: Date): { start: Date; end: Date } {
    const start = new Date(date);
    const end = new Date(date);

    switch (this.viewMode) {
      case 'week':
        // Set to Monday
        start.setDate(start.getDate() - start.getDay() + 1);
        // Set to Sunday
        end.setDate(end.getDate() - end.getDay() + 7);
        break;
      case 'month':
        // Set to first day of month
        start.setDate(1);
        // Set to last day of month
        end.setMonth(end.getMonth() + 1);
        end.setDate(0);
        break;
      default:
        // For daily view, start and end are the same
        break;
    }

    return { start, end };
  }

  private getFormattedPeriodLabel(date: Date): string {
    switch (this.viewMode) {
      case 'week':
        const { start, end } = this.getPeriodRange(date);
        return `Week of ${this.formatDate(start, 'short')} - ${this.formatDate(end, 'short')}`;
      case 'month':
        return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
      default:
        return this.formatDate(date);
    }
  }

  private getSuggestionsByPeriod(): { [key: string]: ISupplierGroup[] } {
    const suggestions: { [key: string]: ISupplierGroup[] } = {};
    
    this.mockData.suppliers.forEach(supplier => {
      supplier.products.forEach((product: IProduct) => {
        const reorderDate = new Date(product.nextReorderDate);
        const periodKey = this.getPeriodKey(reorderDate);
        
        if (!suggestions[periodKey]) {
          suggestions[periodKey] = [];
        }
        
        const existingGroup = suggestions[periodKey].find(
          group => group.supplierId === supplier.id
        );
        
        if (existingGroup) {
          existingGroup.products.push(product);
        } else {
          suggestions[periodKey].push({
            supplierId: supplier.id,
            supplierName: supplier.name,
            supplierBuyingCycle: supplier.buyingCycle,
            products: [product]
          });
        }
      });
    });
    
    return suggestions;
  }

  private getSupplierGroupsByDate(date: Date): ISupplierGroup[] {
    const periodKey = this.getPeriodKey(date);
    return this.getSuggestionsByPeriod()[periodKey] || [];
  }

  private applyDateRange(): void {
    this.isCalendarOpen = false;
  }

  private createBuyingList(group: ISupplierGroup): void {
    // TODO: Implement buying list creation
    console.log('Creating buying list for supplier:', group.supplierName);
  }

  private toggleGroup(supplierId: number): void {
    this.$set(this.expandedGroups, supplierId, !this.expandedGroups[supplierId]);
  }
}
