export const replenishmentMetrics = {
  summary: [
    {
      title: "Products Needing Replenishment",
      value: 247,
      change: +12,
      icon: "mdi-package-variant-closed",
      color: "warning"
    },
    {
      title: "Out of Stock Items",
      value: 54,
      change: -3,
      icon: "mdi-package-variant-remove",
      color: "error"
    },
    {
      title: "Low Stock Items",
      value: 193,
      change: +15,
      icon: "mdi-package-variant",
      color: "orange"
    },
    {
      title: "Active Buying Lists",
      value: 12,
      change: -2,
      icon: "mdi-clipboard-list",
      color: "primary"
    }
  ],
  quickStats: {
    totalInventoryValue: 1245000,
    averageStockLevel: 82,
    stockTurnoverRate: 4.2,
    totalRevenueAtRisk: 325000
  }
}; 