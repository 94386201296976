
import { Component, Vue } from 'vue-property-decorator';
import { SupplierModel } from '@/models/supplier';
import { BuyingListModel } from '@/models/buying_list';
import { BuyingLists } from '@/collections/buying_lists';
import { StoreLocationList } from '@/collections/store_locations';
import { StoreLocationModel } from '@/models/store_location';

@Component
export default class BuyingListDetailView extends Vue {
  private buyingList: BuyingListModel | null = null;
  private supplier: SupplierModel | null = null;
  private search = '';
  private saving = false;
  private isEditing = false;
  private itemChanges: Map<number, { quantity: number, action: 'update' | 'remove' }> = new Map();
  private originalItems: any[] = [];
  private showCancelDialog = false;
  private showEmailDialog = false;
  private emailSubject = '';
  private emailMessage = '';
  private isEmailFormValid = false;
  private sending = false;
  private attachmentFormat: 'pdf' | 'csv' = 'pdf';
  private emailRecipients: string[] = [];
  private storeLocationList = new StoreLocationList();
  private locations: StoreLocationModel[] = [];
  private loadingLocations = false;
  private finalizeLoading = false;
  private cancelLoading = false;

  private headers = [
    { text: 'Variant ID', value: 'productId' },
    { text: 'Name', value: 'productName' },
    { text: 'SKU', value: 'sku' },
    { text: 'Quantity', value: 'quantity', width: '120px' },
    { text: 'Unit Cost', value: 'unitPrice', width: '100px' },
    { text: 'Total', value: 'totalPrice', width: '120px' },
    { text: 'Actions', value: 'actions', sortable: false, width: '100px' }
  ];

  async created() {
    this.fetchLocations();
    await this.fetchData();
  }

  private async fetchData() {
    try {
      const { id } = this.$route.params;
      const buyingLists = new BuyingLists();
      this.buyingList = await buyingLists.getOne(id);

      if (this.buyingList?.supplierId) {
        this.supplier = new SupplierModel({ id: this.buyingList.supplierId });
        await this.supplier.getSupplierDetails();
      }

      // Store original items for comparison
      this.originalItems = JSON.parse(JSON.stringify(this.buyingList?.items || []));
      console.log(this.originalItems);
    } catch (error) {
      console.error('Error fetching buying list:', error);
      this.$emit('error', 'Failed to load buying list details');
    }
  }

  private async fetchLocations() {
    this.loadingLocations = true;
    try {
      await this.storeLocationList.fetch();
      this.locations = this.storeLocationList.items;
    } catch (error) {
      console.error('Error fetching locations:', error);
      this.$emit('error', 'Failed to load store locations');
    } finally {
      this.loadingLocations = false;
    }
  }

  private formatNumber(value: number | undefined): string {
    if (!value) return '0';
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  private getStatusColor(status: string | undefined): string {
    if (!status) return 'grey';
    switch (status) {
      case 'draft':
        return 'grey';
      case 'finalized':
        return 'primary';
      case 'completed':
        return 'success';
      case 'canceled':
        return 'error';
      default:
        return 'grey';
    }
  }

  private getStockLevelColor(item: any): string {
    const stock = item[`[Actual Inventory Quantity]`];
    if (stock <= 0) return 'error';
    if (stock <= 10) return 'warning';
    return 'success';
  }

  get getOrderValueStatus(): string {
    if (!this.supplier || !this.buyingList) return 'grey';
    return this.isValidTotalValue ? 'success' : 'error';
  }

  get getOrderValueMessage(): string {
    if (!this.supplier || !this.buyingList) return 'Invalid';
    const totalValue = parseFloat(this.buyingList.totalValue.toString());
    const minOrderValue = parseFloat(this.supplier.minOrderValue.toString());
    const maxOrderValue = parseFloat(this.supplier.maxOrderValue.toString());

    if (totalValue < minOrderValue) {
      return 'Below Minimum';
    }
    if (totalValue > maxOrderValue) {
      console.log('total value', totalValue);
      console.log('max order value', maxOrderValue);
      return 'Exceeds Maximum';
    }
    return 'Valid Order Value';
  }

  get getOrderValueClass(): string {
    return this.getOrderValueStatus === 'success' ? 'success--text' : 'error--text';
  }

  get isEditable(): boolean {
    return this.buyingList?.status === 'draft';
  }

  get isValidForFinalize(): boolean {
    return !!(
      this.buyingList &&
      this.buyingList.status === 'draft' &&
      this.isValidTotalValue
    );
  }

  get isValidTotalValue(): boolean {
    if (!this.supplier || !this.buyingList) return false;
    const totalValue = parseFloat(this.buyingList.totalValue.toString());
    const minOrderValue = parseFloat(this.supplier.minOrderValue.toString());
    const maxOrderValue = parseFloat(this.supplier.maxOrderValue.toString());
    return totalValue >= minOrderValue && totalValue <= maxOrderValue;
  }

  get locationOptions() {
    return [
      { id: null, name: 'All Locations' },
      ...this.locations
    ];
  }

  private async handleLocationChange() {
    if (!this.buyingList) return;
    // Location changes are saved with other changes when saving the list
  }

  private async toggleEditMode() {
    if (this.isEditing) {
      await this.saveBuyingList();
      this.isEditing = false;
    } else {
      this.isEditing = true;
    }
  }

  private async updateItemQuantity(item: any) {
    if (!this.buyingList || !this.isEditing || !this.isEditable) return;
    
    this.itemChanges.set(item.id, {
      quantity: item.quantity,
      action: 'update'
    });
  }

  private async removeItem(item: any) {
    if (!this.buyingList || !this.isEditing || !this.isEditable) return;
    
    this.itemChanges.set(item.id, {
      quantity: 0,
      action: 'remove'
    });

    const index = this.buyingList.items.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.buyingList.items.splice(index, 1);
    }
  }

  private async saveChanges() {
    if (!this.buyingList || !this.itemChanges.size) return;

    try {
      // Convert changes to the format expected by the API

      const changes = Array.from(this.itemChanges.entries()).map(([id, change]) => {
        const item = this.originalItems.find(i => i.id === id);
        return {
          id,
          productId: item.productId,
          productName: item.productName,
          sku: item.sku,
          quantity: change.quantity,
          unitPrice: item.unitPrice,
          totalPrice: change.quantity * item.unitPrice,
          _action: change.action
        };
      });
      // Save changes in batch
      this.buyingList.setItemChanges(changes);
      await this.buyingList.saveItemChanges();
      
      // Clear changes after successful save
      this.itemChanges.clear();
      // Update original items
      this.originalItems = JSON.parse(JSON.stringify(this.buyingList.items));
      
      this.$emit('success', 'Changes saved successfully');
    } catch (error) {
      console.error('Error saving changes:', error);
      this.$emit('error', 'Failed to save changes');
      // Revert to original items on error
      if (this.buyingList) {
        this.buyingList.items = JSON.parse(JSON.stringify(this.originalItems));
      }
    }
  }

  private async saveBuyingList() {
    if (!this.buyingList) return;
    
    this.saving = true;
    try {
      // Save any pending item changes first
      if (this.itemChanges.size > 0) {
        await this.saveChanges();
      }
      
      // Then save the buying list itself with updated fields
      await this.buyingList.save();
      this.$emit('success', 'Buying list saved successfully');
      this.isEditing = false;
    } catch (error) {
      console.error('Error saving buying list:', error);
      this.$emit('error', 'Failed to save buying list');
    } finally {
      this.saving = false;
    }
  }

  private async finalizeBuyingList() {
    this.finalizeLoading = true;
    if (!this.buyingList || this.buyingList.status !== 'draft') return;
    
    // Check if there are any unsaved changes
    if (this.itemChanges.size > 0) {
      try {
        await this.saveChanges();
        this.finalizeLoading = false;
      } catch (error) {
        this.$emit('error', 'Please save all changes before finalizing');
        this.finalizeLoading = false;
        return;
      }
    }

    // Validate order value
    if (!this.isValidTotalValue) {
      this.$emit('error', 'Order value must be within supplier limits before finalizing');
      return;
    }
    
    this.saving = true;
    try {
      await this.buyingList.updateStatus('finalized');
      this.isEditing = false; // Exit edit mode if active
      this.$emit('success', 'Buying list finalized successfully');
    } catch (error) {
      console.error('Error finalizing buying list:', error);
      this.$emit('error', 'Failed to finalize buying list');
    } finally {
      this.saving = false;
    }
  }

  private async cancelBuyingList() {
    if (!this.buyingList || this.buyingList.status === 'canceled') return;
    this.showCancelDialog = true;
  }

  private async confirmCancel() {
    if (!this.buyingList) return;
    
    this.cancelLoading = true;
    try {
      await this.buyingList.cancelList();
      this.isEditing = false; // Exit edit mode if active
      this.showCancelDialog = false;
      this.$emit('success', 'Buying list canceled successfully');
    } catch (error) {
      console.error('Error canceling buying list:', error);
      this.$emit('error', 'Failed to cancel buying list');
    } finally {
      this.cancelLoading = false;
    }
  }

  private validateEmail(email: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  private removeRecipient(email: string) {
    const index = this.emailRecipients.indexOf(email);
    if (index >= 0) {
      this.emailRecipients.splice(index, 1);
    }
  }

  private async sendBuyingList() {
    if (!this.buyingList || !this.supplier) return;
    
    // Initialize default values
    const totalValue = Number(this.buyingList.totalValue || 0);
    const merchantName = this.$store.state.merchant?.name || 'Merchant';
    const storeName = "Test store";
    
    this.emailSubject = `New Buying List from ${this.supplier.name} - ${this.buyingList.name}`;
    this.emailMessage = `Dear ${this.supplier.name},

Please review the attached buying list and confirm the order details. The total order value is $${totalValue.toFixed(2)}.

Best regards,
${merchantName}`;
    this.attachmentFormat = 'pdf';
    this.emailRecipients = this.supplier.contactEmail ? [this.supplier.contactEmail] : ['customer.service@assisty.ai'];
    
    // Show email dialog
    this.showEmailDialog = true;
  }

  private async confirmSendEmail() {
    if (!this.buyingList || !this.supplier || !this.isEmailFormValid) return;
    
    this.sending = true;
    try {
      await this.buyingList.sendEmail({
        emailSubject: this.emailSubject,
        customMessage: this.emailMessage,
        attachmentFormat: this.attachmentFormat,
        recipients: this.emailRecipients
      });
      
      this.showEmailDialog = false;
      this.$emit('success', 'Buying list sent successfully');
    } catch (error) {
      console.error('Error sending buying list:', error);
      this.$emit('error', 'Failed to send buying list');
    } finally {
      this.sending = false;
    }
  }

  private async convertToPO() {
    if (!this.buyingList || this.buyingList.status !== 'finalized') return;
    
    try {
      // Here you would implement the logic to convert to PO
      // For example, creating a purchase order from the buying list
      this.$emit('success', 'Converting to purchase order...');
      this.$router.push(`/purchase-orders/create?from_list=${this.buyingList.id}`);
    } catch (error) {
      console.error('Error converting to PO:', error);
      this.$emit('error', 'Failed to convert to purchase order');
    }
  }

  get locationName(): string {
    if (!this.buyingList?.locationId) return 'All Locations';
    return this.locations.find(l => l.id === this.buyingList?.locationId)?.name || 'Unknown Location';
  }
}
