import { IIdentificationMetric, IReorderFormula } from '@/models/sourcing_strategy';

export type Strategy = {
  id: string;
  icon: string;
  iconColor: string;
  title: string;
  description: string;
  category: 'demand' | 'supplier' | 'finance' | 'inventory';
  primaryMetric: {
    value: string;
    label: string;
  };
};

export interface IProduct {
  id: number;
  name: string;
  sku: string;
  currentStock: number;
  recommendedReorderQuantity: number;
  unitCost: number;
  reorderQuantity: number;
}

export interface IStrategyMetric {
  value: string;
  label: string;
  code?: string;
  trend?: {
    value: number;
    isPositive: boolean;
  };
}

export interface IStrategyDetails extends Strategy {
  metrics: IStrategyMetric[];
}

export const strategyData = {
  strategies: [
    // Demand-based Strategies
    {
      id: 'high-priority',
      icon: 'mdi-rocket-launch',
      iconColor: 'error',
      title: 'High-Priority Restocking',
      description: 'Products with critical inventory levels requiring immediate attention',
      category: 'demand',
      primaryMetric: {
        value: '15',
        label: 'Products'
      }
    },
    {
      id: 'trending',
      icon: 'mdi-trending-up',
      iconColor: 'success',
      title: 'Trending Products',
      description: 'Items showing increased demand patterns in the last 30 days',
      category: 'demand',
      primaryMetric: {
        value: '8',
        label: 'Products'
      }
    },
    {
      id: 'seasonal',
      icon: 'mdi-calendar-clock',
      iconColor: 'info',
      title: 'Seasonal Preparation',
      description: 'Products requiring stock adjustment based on upcoming seasonal demand',
      category: 'demand',
      primaryMetric: {
        value: '12',
        label: 'Products'
      }
    },
    {
        id: 'new-products',
        icon: 'mdi-star-outline',
        iconColor: 'amber',
        title: 'New Product Launch',
        description: 'Stock planning for newly launched products',
        category: 'demand',
        primaryMetric: {
          value: '9',
          label: 'Products'
        }
      },
      {
        id: 'demand-forecast',
        icon: 'mdi-chart-line',
        iconColor: 'cyan',
        title: 'Demand Forecast Alert',
        description: 'Products with significant changes in demand forecast',
        category: 'demand',
        primaryMetric: {
          value: '14',
          label: 'Products'
        }
      },
      {
        id: 'regional-trends',
        icon: 'mdi-map-marker',
        iconColor: 'deep-orange',
        title: 'Regional Demand Patterns',
        description: 'Products showing strong regional sales variations',
        category: 'demand',
        primaryMetric: { value: '11', label: 'Regions' }
      },

    // Supplier-based Strategies
    {
      id: 'supplier-moq',
      icon: 'mdi-truck-delivery',
      iconColor: 'purple',
      title: 'MOQ Optimization',
      description: 'Orders that can be combined to meet supplier minimum order quantities',
      category: 'supplier',
      primaryMetric: {
        value: '5',
        label: 'Suppliers'
      }
    },
    {
      id: 'lead-time',
      icon: 'mdi-clock-fast',
      iconColor: 'orange',
      title: 'Lead Time Alert',
      description: 'Products requiring early reorder due to extended supplier lead times',
      category: 'supplier',
      primaryMetric: {
        value: '10',
        label: 'Products'
      }
    },

    // {
    //     id: 'bulk-discount',
    //     icon: 'mdi-tag-multiple',
    //     iconColor: 'deep-purple',
    //     title: 'Bulk Discount Opportunities',
    //     description: 'Orders eligible for supplier volume discounts',
    //     category: 'supplier',
    //     primaryMetric: {
    //       value: '3',
    //       label: 'Suppliers'
    //     }
    //   },
      {
        id: 'supplier-consolidation',
        icon: 'mdi-package-variant-closed',
        iconColor: 'indigo',
        title: 'Order Consolidation',
        description: 'Opportunities to combine orders from the same supplier',
        category: 'supplier',
        primaryMetric: {
          value: '4',
          label: 'Orders'
        }
      },
    // Finance-based Strategies
    

    {
      id: 'high-value',
      icon: 'mdi-diamond-stone',
      iconColor: 'blue',
      title: 'High-Value Items',
      description: 'Strategic purchasing for high-value inventory items',
      category: 'finance',
      primaryMetric: {
        value: '6',
        label: 'Products'
      }
    },

    {
        id: 'roi-optimization',
        icon: 'mdi-chart-bell-curve',
        iconColor: 'success',
        title: 'ROI Optimization',
        description: 'Products ranked by return on investment potential',
        category: 'finance',
        primaryMetric: { value: '15%', label: 'Avg ROI' }
      },
      {
        id: 'inventory-turnover',
        icon: 'mdi-refresh',
        iconColor: 'cyan',
        title: 'Turnover Improvement',
        description: 'Recommendations to improve inventory turnover rate',
        category: 'finance',
        primaryMetric: { value: '8.5x', label: 'Target' }
      },
      {
        id: 'margin-optimization',
        icon: 'mdi-percent',
        iconColor: 'deep-purple',
        title: 'Margin Enhancement',
        description: 'Products with margin improvement opportunities',
        category: 'finance',
        primaryMetric: { value: '12', label: 'Products' }
      },
    // Inventory-based Strategies
    {
      id: 'optimization',
      icon: 'mdi-lightning-bolt',
      iconColor: 'warning',
      title: 'Stock Optimization',
      description: 'Products with potential for order quantity optimization',
      category: 'inventory',
      primaryMetric: {
        value: '18',
        label: 'Products'
      }
    },
    {
      id: 'overstock',
      icon: 'mdi-package-variant',
      iconColor: 'red',
      title: 'Overstock Prevention',
      description: 'Items at risk of becoming overstocked based on demand patterns',
      category: 'inventory',
      primaryMetric: {
        value: '7',
        label: 'Products'
      }
    },
    // Demand-based additions
    {
      id: 'new-products',
      icon: 'mdi-star-outline',
      iconColor: 'amber',
      title: 'New Product Launch',
      description: 'Stock planning for newly launched products',
      category: 'demand',
      primaryMetric: {
        value: '9',
        label: 'Products'
      }
    },
    {
      id: 'demand-forecast',
      icon: 'mdi-chart-line',
      iconColor: 'cyan',
      title: 'Demand Forecast Alert',
      description: 'Products with significant changes in demand forecast',
      category: 'demand',
      primaryMetric: {
        value: '14',
        label: 'Products'
      }
    },
    // Supplier-based additions
    {
      id: 'bulk-discount',
      icon: 'mdi-tag-multiple',
      iconColor: 'deep-purple',
      title: 'Bulk Discount Opportunities',
      description: 'Orders eligible for supplier volume discounts',
      category: 'supplier',
      primaryMetric: {
        value: '3',
        label: 'Suppliers'
      }
    },
    {
      id: 'supplier-consolidation',
      icon: 'mdi-package-variant-closed',
      iconColor: 'indigo',
      title: 'Order Consolidation',
      description: 'Opportunities to combine orders from the same supplier',
      category: 'supplier',
      primaryMetric: {
        value: '4',
        label: 'Orders'
      }
    },
    // More Demand-based Strategies
    {
      id: 'regional-trends',
      icon: 'mdi-map-marker',
      iconColor: 'deep-orange',
      title: 'Regional Demand Patterns',
      description: 'Products showing strong regional sales variations',
      category: 'demand',
      primaryMetric: { value: '11', label: 'Regions' }
    },
    {
      id: 'customer-segment',
      icon: 'mdi-account-group',
      iconColor: 'pink',
      title: 'Customer Segment Analysis',
      description: 'Stock recommendations based on customer segment behavior',
      category: 'demand',
      primaryMetric: { value: '6', label: 'Segments' }
    },
    {
      id: 'market-events',
      icon: 'mdi-calendar-star',
      iconColor: 'purple',
      title: 'Market Event Planning',
      description: 'Stock planning for upcoming market events and promotions',
      category: 'demand',
      primaryMetric: { value: '8', label: 'Events' }
    },
    {
      id: 'competitor-impact',
      icon: 'mdi-target',
      iconColor: 'red',
      title: 'Competitive Impact',
      description: 'Products affected by competitor actions and market changes',
      category: 'demand',
      primaryMetric: { value: '13', label: 'Products' }
    },
    // More Supplier-based Strategies
    {
      id: 'early-payment',
      icon: 'mdi-clock-check',
      iconColor: 'green',
      title: 'Early Payment Discounts',
      description: 'Orders eligible for early payment discount terms',
      category: 'supplier',
      primaryMetric: { value: '$15K', label: 'Savings' }
    },
    {
      id: 'preferred-supplier',
      icon: 'mdi-star',
      iconColor: 'amber',
      title: 'Preferred Supplier Program',
      description: 'Opportunities with preferred supplier benefits',
      category: 'supplier',
      primaryMetric: { value: '7', label: 'Suppliers' }
    },
    {
      id: 'supplier-risk',
      icon: 'mdi-alert',
      iconColor: 'error',
      title: 'Supplier Risk Management',
      description: 'Orders affected by supplier performance or risk factors',
      category: 'supplier',
      primaryMetric: { value: '4', label: 'Suppliers' }
    },
    {
      id: 'new-supplier',
      icon: 'mdi-handshake',
      iconColor: 'blue',
      title: 'New Supplier Opportunities',
      description: 'Products eligible for new supplier relationships',
      category: 'supplier',
      primaryMetric: { value: '5', label: 'Suppliers' }
    },
    // More Finance-based Strategies
    {
      id: 'roi-optimization',
      icon: 'mdi-chart-bell-curve',
      iconColor: 'success',
      title: 'ROI Optimization',
      description: 'Products ranked by return on investment potential',
      category: 'finance',
      primaryMetric: { value: '15%', label: 'Avg ROI' }
    },
    {
      id: 'inventory-turnover',
      icon: 'mdi-refresh',
      iconColor: 'cyan',
      title: 'Turnover Improvement',
      description: 'Recommendations to improve inventory turnover rate',
      category: 'finance',
      primaryMetric: { value: '8.5x', label: 'Target' }
    },
    {
      id: 'working-capital',
      icon: 'mdi-bank',
      iconColor: 'indigo',
      title: 'Working Capital Management',
      description: 'Optimize inventory levels for working capital efficiency',
      category: 'finance',
      primaryMetric: { value: '$50K', label: 'Impact' }
    },
    {
      id: 'margin-optimization',
      icon: 'mdi-percent',
      iconColor: 'deep-purple',
      title: 'Margin Enhancement',
      description: 'Products with margin improvement opportunities',
      category: 'finance',
      primaryMetric: { value: '12', label: 'Products' }
    },
    // More Inventory-based Strategies
    {
      id: 'warehouse-capacity',
      icon: 'mdi-warehouse',
      iconColor: 'brown',
      title: 'Warehouse Capacity',
      description: 'Orders optimized for available storage space',
      category: 'inventory',
      primaryMetric: { value: '85%', label: 'Capacity' }
    },
    {
      id: 'expiration-risk',
      icon: 'mdi-timer-sand',
      iconColor: 'red',
      title: 'Expiration Risk',
      description: 'Products at risk of expiration or obsolescence',
      category: 'inventory',
      primaryMetric: { value: '9', label: 'Products' }
    },

    // Inventory-based Strategies

    {
      id: 'cross-location',
      icon: 'mdi-store',
      iconColor: 'teal',
      title: 'Cross-Location Balance',
      description: 'Balance inventory across multiple locations',
      category: 'inventory',
      primaryMetric: { value: '6', label: 'Locations' }
    },

    // {
    //   id: 'kit-components',
    //   icon: 'mdi-puzzle',
    //   iconColor: 'orange',
    //   title: 'Kit Component Analysis',
    //   description: 'Ensure adequate stock of product kit components',
    //   category: 'inventory',
    //   primaryMetric: { value: '7', label: 'Kits' }
    // }
    {
      id: 'safety-stock',
      icon: 'mdi-shield-check',
      iconColor: 'light-blue',
      title: 'Safety Stock Review',
      description: 'Recommendations for safety stock level adjustments',
      category: 'inventory',
      primaryMetric: { value: '16', label: 'Products' }
    },
    {
      id: 'slow-moving',
      icon: 'mdi-snail',
      iconColor: 'grey',
      title: 'Slow-Moving Inventory',
      description: 'Products with low turnover rates requiring attention',
      category: 'inventory',
      primaryMetric: { value: '11', label: 'Products' }
    },
    {
      id: 'kit-components',
      icon: 'mdi-puzzle',
      iconColor: 'orange',
      title: 'Kit Component Analysis',
      description: 'Ensure adequate stock of product kit components',
      category: 'inventory',
      primaryMetric: { value: '7', label: 'Kits' }
    }

  ],
  
  mockProducts: {
    'high-priority': [
      {
        id: 1,
        name: 'Premium Coffee Beans',
        sku: 'COF001',
        currentStock: 5,
        recommendedReorderQuantity: 20,
        unitCost: 24.99,
        reorderQuantity: 0
      },
      {
        id: 2,
        name: 'Organic Tea Bags',
        sku: 'TEA002', 
        currentStock: 8,
        recommendedReorderQuantity: 15,
        unitCost: 18.50,
        reorderQuantity: 0
      }
    ],
    'trending': [
      {
        id: 3,
        name: 'Ceramic Mug',
        sku: 'MUG001',
        currentStock: 12,
        recommendedReorderQuantity: 25,
        unitCost: 12.99,
        reorderQuantity: 0
      },
      {
        id: 4,
        name: 'Travel Tumbler',
        sku: 'TUM001',
        currentStock: 15,
        recommendedReorderQuantity: 30,
        unitCost: 19.99,
        reorderQuantity: 0
      }
    ],
    'seasonal': [
      {
        id: 5,
        name: 'Pumpkin Spice Syrup',
        sku: 'SYR001',
        currentStock: 25,
        recommendedReorderQuantity: 50,
        unitCost: 8.99,
        reorderQuantity: 0
      },
      {
        id: 6,
        name: 'Holiday Blend Coffee',
        sku: 'COF002',
        currentStock: 30,
        recommendedReorderQuantity: 60,
        unitCost: 22.99,
        reorderQuantity: 0
      }
    ],
    'new-products': [
      {
        id: 7,
        name: 'Cold Brew Maker',
        sku: 'BRW001',
        currentStock: 20,
        recommendedReorderQuantity: 40,
        unitCost: 34.99,
        reorderQuantity: 0
      }
    ],
    'demand-forecast': [
      {
        id: 8,
        name: 'Coffee Grinder',
        sku: 'GRD001',
        currentStock: 18,
        recommendedReorderQuantity: 35,
        unitCost: 45.99,
        reorderQuantity: 0
      }
    ],
    'supplier-moq': [
      {
        id: 9,
        name: 'Paper Cups',
        sku: 'CUP001',
        currentStock: 200,
        recommendedReorderQuantity: 1000,
        unitCost: 0.15,
        reorderQuantity: 0
      }
    ],
    'lead-time': [
      {
        id: 10,
        name: 'Specialty Coffee Beans',
        sku: 'COF003',
        currentStock: 40,
        recommendedReorderQuantity: 100,
        unitCost: 29.99,
        reorderQuantity: 0
      }
    ],
    'supplier-consolidation': [
      {
        id: 11,
        name: 'Tea Infuser',
        sku: 'TEA003',
        currentStock: 25,
        recommendedReorderQuantity: 50,
        unitCost: 15.99,
        reorderQuantity: 0
      }
    ],
    'cross-location': [
      {
        id: 12,
        name: 'Coffee Machine',
        sku: 'MAC001',
        currentStock: 10,
        recommendedReorderQuantity: 20,
        unitCost: 299.99,
        reorderQuantity: 0
      }
    ],
    'roi-optimization': [
      {
        id: 13,
        name: 'Premium Tea Set',
        sku: 'SET001',
        currentStock: 15,
        recommendedReorderQuantity: 30,
        unitCost: 89.99,
        reorderQuantity: 0
      }
    ],
    'inventory-turnover': [
      {
        id: 14,
        name: 'Coffee Pods',
        sku: 'POD001',
        currentStock: 100,
        recommendedReorderQuantity: 200,
        unitCost: 0.99,
        reorderQuantity: 0
      }
    ],
    'margin-optimization': [
      {
        id: 15,
        name: 'Gift Card',
        sku: 'GFT001',
        currentStock: 50,
        recommendedReorderQuantity: 100,
        unitCost: 0,
        reorderQuantity: 0
      }
    ]
  }
};

export const strategyCategories = [
  {
    id: 'demand',
    title: 'Demand-Based Strategies',
    description: 'Strategies based on customer demand patterns and trends'
  },
  {
    id: 'supplier',
    title: 'Supplier-Based Strategies',
    description: 'Optimize ordering based on supplier terms and conditions'
  },
  {
    id: 'finance',
    title: 'Finance-Based Strategies',
    description: 'Strategies focusing on financial optimization and cash flow'
  },
  {
    id: 'inventory',
    title: 'Inventory-Based Strategies',
    description: 'Optimize stock levels and prevent inventory issues'
  }
];

export const strategyMetrics: Record<string, IStrategyMetric[]> = {
  'high-priority': [
    {
      value: '15',
      label: 'Critical Products',
      trend: { value: 3, isPositive: false }
    },
    {
      value: '$12,450',
      label: 'Revenue at Risk', 
      trend: { value: 15, isPositive: false }
    },
    {
      value: '85%',
      label: 'Stock Level',
      trend: { value: 5, isPositive: true }
    },
    {
      value: '7 days',
      label: 'Lead Time',
      trend: { value: 2, isPositive: true }
    }
  ],
  'trending': [
    {
      value: '8',
      label: 'Trending Products',
      trend: { value: 12, isPositive: true }
    },
    {
      value: '+25%',
      label: 'Demand Growth Rate',
      trend: { value: 25, isPositive: true }
    },
    {
      value: '$45,000',
      label: 'Revenue Contribution',
      trend: { value: 18, isPositive: true }
    },
    {
      value: '95%',
      label: 'In-Stock Rate',
      trend: { value: 3, isPositive: true }
    }
  ],
  'seasonal': [
    {
      value: '12',
      label: 'Seasonal Products',
      trend: { value: 4, isPositive: true }
    },
    {
      value: '1.5x',
      label: 'Seasonal Index',
      trend: { value: 15, isPositive: true }
    },
    {
      value: '300',
      label: 'Historical Sales',
      trend: { value: 5, isPositive: true }
    },
    {
      value: '45 days',
      label: 'Prep Time',
      trend: { value: 2, isPositive: true }
    }
  ],
  'new-products': [
    {
      value: '9',
      label: 'New Products',
      trend: { value: 3, isPositive: true }
    },
    {
      value: '500',
      label: 'Initial Demand Est.',
      trend: { value: 8, isPositive: true }
    },
    {
      value: '30 days',
      label: 'Launch Period',
      trend: { value: 0, isPositive: true }
    },
    {
      value: '85%',
      label: 'Stock Coverage',
      trend: { value: 5, isPositive: true }
    }
  ],
  'demand-forecast': [
    {
      value: '14',
      label: 'Products Affected',
      trend: { value: 4, isPositive: false }
    },
    {
      value: '25%',
      label: 'Forecast Variance',
      trend: { value: 15, isPositive: false }
    },
    {
      value: '400',
      label: 'Updated Forecast',
      trend: { value: 12, isPositive: true }
    },
    {
      value: '92%',
      label: 'Forecast Accuracy',
      trend: { value: 3, isPositive: true }
    }
  ],
  'high-value': [
    {
      value: '6',
      label: 'High-Value Items',
      trend: { value: 2, isPositive: true }
    },
    {
      value: '$500+',
      label: 'Unit Cost',
      trend: { value: 0, isPositive: true }
    },
    {
      value: '$10K+',
      label: 'Revenue Contribution',
      trend: { value: 15, isPositive: true }
    },
    {
      value: '95%',
      label: 'Service Level',
      trend: { value: 4, isPositive: true }
    }
  ],
  'roi-optimization': [
    {
      value: '8',
      label: 'Products',
      trend: { value: 2, isPositive: true }
    },
    {
      value: '50%',
      label: 'ROI Score',
      trend: { value: 12, isPositive: true }
    },
    {
      value: '$5K+',
      label: 'Revenue Impact',
      trend: { value: 8, isPositive: true }
    },
    {
      value: '20',
      label: 'Safety Stock',
      trend: { value: 0, isPositive: true }
    }
  ],
  'turnover': [
    {
      value: '12',
      label: 'Products',
      trend: { value: 3, isPositive: false }
    },
    {
      value: '2.0',
      label: 'Turnover Ratio',
      trend: { value: 8, isPositive: false }
    },
    {
      value: '60',
      label: 'Avg Days in Stock',
      trend: { value: 5, isPositive: false }
    },
    {
      value: '50',
      label: 'Safety Stock',
      trend: { value: 0, isPositive: true }
    }
  ],
  'margin': [
    {
      value: '10',
      label: 'Products',
      trend: { value: 4, isPositive: true }
    },
    {
      value: '10%',
      label: 'Profit Margin',
      trend: { value: 15, isPositive: true }
    },
    {
      value: '500+',
      label: 'Sales Volume',
      trend: { value: 8, isPositive: true }
    },
    {
      value: '$50K',
      label: 'Budget Cap',
      trend: { value: 0, isPositive: true }
    }
  ],
  // metrics for supplier-based strategies
  'supplier-moq': [
    {
      value: '5',
      label: 'MOQ Opportunities',
      trend: { value: 2, isPositive: true }
    },
    {
      value: '$25,000',
      label: 'Order Value',
      trend: { value: 10, isPositive: true }
    },
    {
      value: '30 days',
      label: 'Lead Time',
      trend: { value: 0, isPositive: true }
    }
  ],
  'lead-time': [
    {
      value: '10',
      label: 'Products',
      trend: { value: 2, isPositive: false }
    },
    {
      value: '45 days',
      label: 'Avg Lead Time',
      trend: { value: 5, isPositive: false }
    },
    {
      value: '85%',
      label: 'Service Level',
      trend: { value: 3, isPositive: false }
    }
  ],
  'supplier-consolidation': [
    {
      value: '4',
      label: 'Orders',
      trend: { value: 1, isPositive: true }
    },
    {
      value: '3',
      label: 'Suppliers',
      trend: { value: 0, isPositive: true }
    },
    {
      value: '$15,000',
      label: 'Combined Value',
      trend: { value: 12, isPositive: true }
    }
  ],
  'cross-location': [
    {
      value: '6',
      label: 'Locations',
      trend: { value: 2, isPositive: false }
    },
    {
      value: '85%',
      label: 'Stock Balance',
      trend: { value: 5, isPositive: true }
    },
    {
      value: '24 hrs',
      label: 'Transfer Time',
      trend: { value: 0, isPositive: true }
    }
  ],
  'inventory-turnover': [
    {
      value: '2.0',
      label: 'Turnover Ratio',
      trend: { value: 15, isPositive: false }
    },
    {
      value: '60 days',
      label: 'Avg Days in Stock',
      trend: { value: 10, isPositive: false }
    },
    {
      value: '$45,000',
      label: 'Stock Value',
      trend: { value: 8, isPositive: false }
    }
  ],
  'margin-optimization': [
    {
      value: '10%',
      label: 'Profit Margin',
      trend: { value: 15, isPositive: true }
    },
    {
      value: '500+',
      label: 'Sales Volume',
      trend: { value: 8, isPositive: true }
    },
    {
      value: '$50K',
      label: 'Budget Cap',
      trend: { value: 0, isPositive: true }
    }
  ]
};

export const strategyMetricRules: Record<string, IIdentificationMetric[]> = {
  'high-priority': [
    {
      metric: 'stock_level_threshold',
      comparison: 'value',
      operator: '<',
      threshold: 20
    },
    {
      metric: 'monthly_revenue',
      comparison: 'value',
      operator: '>',
      threshold: 1000
    }
  ],
  'trending': [
    {
      metric: 'monthly_growth_rate',
      comparison: 'value',
      operator: '>',
      threshold: 25
    },
    {
      metric: 'monthly_sales_volume',
      comparison: 'value',
      operator: '>',
      threshold: 100
    }
  ],
  'seasonal': [
    {
      metric: 'seasonal_index',
      comparison: 'value',
      operator: '>',
      threshold: 1.5
    },
    {
      metric: 'time_to_season',
      comparison: 'value',
      operator: '<',
      threshold: 60
    }
  ],
  'new-products': [
    {
      metric: 'product_age',
      comparison: 'value',
      operator: '<',
      threshold: 90
    },
    {
      metric: 'initial_stock_level',
      comparison: 'value',
      operator: '>',
      threshold: 50
    }
  ],
  'demand-forecast': [
    {
      metric: 'forecast_deviation',
      comparison: 'value',
      operator: '>',
      threshold: 20
    },
    {
      metric: 'monthly_demand',
      comparison: 'value',
      operator: '>',
      threshold: 100
    }
  ],
  'supplier-moq': [
    {
      metric: 'current_order_size',
      comparison: 'metric',
      operator: '<',
      threshold: 0,
      secondMetric: 'moq_value'
    },
    {
      metric: 'moq_value',
      comparison: 'value',
      operator: '>',
      threshold: 5000
    }
  ],
  'lead-time': [
    {
      metric: 'supplier_lead_time',
      comparison: 'value',
      operator: '>',
      threshold: 30
    },
    {
      metric: 'safety_stock_level',
      comparison: 'value',
      operator: '<',
      threshold: 50
    }
  ],
  'supplier-consolidation': [
    {
      metric: 'open_orders',
      comparison: 'value',
      operator: '>=',
      threshold: 2
    },
    {
      metric: 'order_time_gap',
      comparison: 'value',
      operator: '<',
      threshold: 7
    }
  ],
  'high-value': [
    {
      metric: 'avg_order_value',
      comparison: 'value',
      operator: '>',
      threshold: 500
    },
    {
      metric: 'monthly_revenue',
      comparison: 'value',
      operator: '>',
      threshold: 10000
    }
  ],
  'roi-optimization': [
    {
      metric: 'profit_margin',
      comparison: 'value',
      operator: '>',
      threshold: 30
    },
    {
      metric: 'turnover_rate',
      comparison: 'value',
      operator: '<',
      threshold: 45
    }
  ],
  'inventory-turnover': [
    {
      metric: 'inventory_turnover',
      comparison: 'value',
      operator: '<',
      threshold: 4.0
    },
    {
      metric: 'days_stock',
      comparison: 'value',
      operator: '>',
      threshold: 90
    }
  ],
  'margin-optimization': [
    {
      metric: 'profit_margin',
      comparison: 'value',
      operator: '<',
      threshold: 15
    },
    {
      metric: 'growth_rate',
      comparison: 'value',
      operator: '>',
      threshold: 20
    }
  ],
  'cross-location': [
    {
      metric: 'stock_availability',
      comparison: 'value',
      operator: '>',
      threshold: 30
    },
    {
      metric: 'lead_time',
      comparison: 'value',
      operator: '<',
      threshold: 48
    }
  ]
};

export const availableMetrics = [
  { text: 'Stock Level (Units)', value: 'stock_level' },
  { text: 'Days of Stock (Days)', value: 'days_stock' },
  { text: 'Stock Availability (%)', value: 'stock_availability' },
  { text: 'Out of Stock Days (Days)', value: 'out_of_stock_days' },
  { text: 'Stockout Rate (%)', value: 'stockout_rate' },
  { text: 'Reorder Point (Units)', value: 'reorder_point' },
  { text: 'Average Daily Demand', value: 'avg_daily_demand' },
  { text: 'Demand Growth Rate', value: 'demand_growth_rate' },
  { text: 'Total Sales ($)', value: 'total_sales' },
  { text: 'Average Order Value ($)', value: 'avg_order_value' },
  { text: 'Revenue at Risk ($)', value: 'revenue_risk' },
  { text: 'Profit Margin (%)', value: 'profit_margin' },
  { text: 'Growth Rate (%)', value: 'growth_rate' },
  { text: 'Turnover Rate (Times/Year)', value: 'turnover_rate' },
  { text: 'Inventory Turnover (Times/Year)', value: 'inventory_turnover' },
  { text: 'Sell Through Rate (%)', value: 'sell_through_rate' },
  { text: 'Inventory to Sales Ratio (Ratio)', value: 'inventory_to_sales_ratio' },
  { text: 'Monthly Revenue ($)', value: 'monthly_revenue' },
  { text: 'Stock Level Threshold (%)', value: 'stock_level_threshold' },
  { text: 'Monthly Growth Rate (%)', value: 'monthly_growth_rate' },
  { text: 'Monthly Sales Volume', value: 'monthly_sales_volume' },
  { text: 'Seasonal Index', value: 'seasonal_index' },
  { text: 'Time to Season (Days)', value: 'time_to_season' },
  { text: 'Product Age (Days)', value: 'product_age' },
  { text: 'Initial Stock Level', value: 'initial_stock_level' },
  { text: 'Forecast Deviation (%)', value: 'forecast_deviation' },
  { text: 'Monthly Demand', value: 'monthly_demand' },
  { text: 'Current Order Size', value: 'current_order_size' },
  { text: 'MOQ Value ($)', value: 'moq_value' },
  { text: 'Supplier Lead Time (Days)', value: 'supplier_lead_time' },
  { text: 'Safety Stock Level (%)', value: 'safety_stock_level' },
  { text: 'Open Orders', value: 'open_orders' },
  { text: 'Order Time Gap (Days)', value: 'order_time_gap' }
];

export const reorderMetrics = [
  { text: 'Inventory On-hand', value: 'inventory_on_hand' },
  { text: 'Incoming Inventory', value: 'incoming_inventory' },
  { text: 'Lead Time Demand', value: 'lead_time_demand' },
  { text: 'Safety Stock', value: 'safety_stock' },
  { text: 'Average Daily Demand', value: 'avg_daily_demand' },
  { text: 'Lead Time', value: 'lead_time' },
  { text: 'Min Order Quantity', value: 'min_order_qty' },
  { text: 'Max Order Quantity', value: 'max_order_qty' },
  { text: 'Standard Deviation of Demand', value: 'demand_std_dev' },
  { text: 'Service Level Z-Score', value: 'service_level_z' }
];

export const strategyReorderFormulas: Record<string, IReorderFormula> = {
  'high-priority': {
    type: 'default',
    components: [
      { type: 'metric', value: 'lead_time_demand' },
      { type: 'operator', value: '+' },
      { type: 'metric', value: 'safety_stock' },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'incoming_inventory' }
    ],
    formula: 'lead_time_demand + safety_stock - incoming_inventory'
  },
  'trending': {
    type: 'default',
    components: [
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'value', value: 1.25 },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'inventory_on_hand' }
    ],
    formula: 'avg_daily_demand * 1.25 * lead_time - inventory_on_hand'
  },
  'seasonal': {
    type: 'default',
    components: [
      { type: 'metric', value: 'seasonal_index' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'inventory_on_hand' }
    ],
    formula: 'seasonal_index * avg_daily_demand * lead_time - inventory_on_hand'
  },
  'new-products': {
    type: 'default',
    components: [
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'value', value: 1.5 },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: '+' },
      { type: 'metric', value: 'safety_stock' }
    ],
    formula: 'avg_daily_demand * 1.5 * lead_time + safety_stock'
  },
  'demand-forecast': {
    type: 'default',
    components: [
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: '+' },
      { type: 'metric', value: 'demand_std_dev' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'service_level_z' },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'inventory_on_hand' }
    ],
    formula: 'avg_daily_demand * lead_time + demand_std_dev * service_level_z - inventory_on_hand'
  },
  'supplier-moq': {
    type: 'default',
    components: [
      { type: 'metric', value: 'min_order_qty' },
      { type: 'operator', value: '+' },
      { type: 'operator', value: '(' },
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'inventory_on_hand' },
      { type: 'operator', value: ')' }
    ],
    formula: 'min_order_qty + (avg_daily_demand * lead_time - inventory_on_hand)'
  },
  'lead-time': {
    type: 'default',
    components: [
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: '*' },
      { type: 'value', value: 1.2 },
      { type: 'operator', value: '+' },
      { type: 'metric', value: 'safety_stock' }
    ],
    formula: 'avg_daily_demand * lead_time * 1.2 + safety_stock'
  },
  'supplier-consolidation': {
    type: 'default',
    components: [
      { type: 'metric', value: 'max_order_qty' },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'inventory_on_hand' },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'incoming_inventory' }
    ],
    formula: 'max_order_qty - inventory_on_hand - incoming_inventory'
  },
  'high-value': {
    type: 'default',
    components: [
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: '+' },
      { type: 'metric', value: 'safety_stock' },
      { type: 'operator', value: '*' },
      { type: 'value', value: 0.8 }
    ],
    formula: 'avg_daily_demand * lead_time + safety_stock * 0.8'
  },
  'roi-optimization': {
    type: 'default',
    components: [
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: '*' },
      { type: 'value', value: 0.9 },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'inventory_on_hand' }
    ],
    formula: 'avg_daily_demand * lead_time * 0.9 - inventory_on_hand'
  },
  'inventory-turnover': {
    type: 'default',
    components: [
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'value', value: 30 },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'inventory_on_hand' }
    ],
    formula: 'avg_daily_demand * 30 - inventory_on_hand'
  },
  'margin-optimization': {
    type: 'default',
    components: [
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: '*' },
      { type: 'value', value: 0.85 },
      { type: 'operator', value: '+' },
      { type: 'metric', value: 'safety_stock' }
    ],
    formula: 'avg_daily_demand * lead_time * 0.85 + safety_stock'
  },
  'cross-location': {
    type: 'default',
    components: [
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: '+' },
      { type: 'metric', value: 'safety_stock' },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'incoming_inventory' }
    ],
    formula: 'avg_daily_demand * lead_time + safety_stock - incoming_inventory'
  }
}; 