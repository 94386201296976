
import { Component, Vue, Watch } from 'vue-property-decorator';
import { BuyingLists, BuyingListsFilter } from '@/collections/buying_lists';
import { BuyingListModel } from '@/models/buying_list';
import { SupplierModel } from '@/models/supplier';
import { StoreLocationModel } from '@/models/store_location';
import { StoreLocationList } from '@/collections/store_locations';
import { SupplierList } from '@/collections/suppliers';

@Component
export default class BuyingListsView extends Vue {
  private buyingLists: BuyingListModel[] = [];
  private loading = false;
  private totalItems = 0;
  private search = '';
  private suppliers: SupplierModel[] = [];
  private locations: StoreLocationModel[] = [];
  private loadingSuppliers = false;
  private loadingLocations = false;

  private filters = {
    status: null as string | null,
    supplierId: null as number | null,
    locationId: null as number | null
  };

  private options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['createdAt'],
    sortDesc: [true]
  };

  private headers = [
    { text: 'Name', value: 'name' },
    { text: 'Supplier', value: 'supplier.name' },
    { text: 'Location', value: 'locationName' },
    { text: 'Status', value: 'status' },
    { text: 'Total Value', value: 'totalValue' },
    { text: 'Created', value: 'createdAt' },
    { text: 'Updated', value: 'updatedAt' },
    { text: 'Actions', value: 'actions', sortable: false }
  ];

  private statusOptions = [
    { text: 'Draft', value: 'draft' },
    { text: 'Finalized', value: 'finalized' },
    { text: 'Completed', value: 'completed' },
    { text: 'Canceled', value: 'canceled' }
  ];

  private showSupplierDialog = false;
  private selectedSupplierId: number | null = null;

  async created() {
    this.fetchSuppliers();
    this.fetchLocations();
    await this.fetchData();
  }

  @Watch('options', { deep: true })
  @Watch('filters', { deep: true })
  private async onFiltersChange() {
    // Check if any filters are active
    const hasActiveFilters = 
      this.filters.status !== null || 
      this.filters.supplierId !== null || 
      this.filters.locationId !== null;

    if (hasActiveFilters) {
      await this.fetchData();
    }
  }

  private async fetchData() {
    this.loading = true;
    try {
      const buyingLists = new BuyingLists();
      const params: BuyingListsFilter = {
        page: this.options.page,
        limit: this.options.itemsPerPage
      };

      if (this.filters.status) {
        params.status = this.filters.status;
      }

      if (this.filters.supplierId) {
        params.supplierId = this.filters.supplierId;
      }

      if (this.filters.locationId) {
        params.locationId = this.filters.locationId;
      }

      if (this.search) {
        params.search = this.search;
      }

      await buyingLists.fetch(params);
      this.buyingLists = buyingLists.items;
      this.totalItems = buyingLists.total;
      console.log(this.buyingLists);
      this.loading = false;
    } catch (error) {
      console.error('Error fetching buying lists:', error);
      this.$emit('error', 'Failed to load buying lists');
    } finally {
      this.loading = false;
    }
  }

  @Watch('search')
  private onSearchChange() {
    // Debounce search input
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.fetchData();
    }, 300);
  }

  private searchTimeout: any = null;

  private async fetchSuppliers() {
    this.loadingSuppliers = true;
    try {
      const suppliers = new SupplierList();
      await suppliers.fetch();
      this.suppliers = suppliers.items;
    } catch (error) {
      console.error('Error fetching suppliers:', error);
      this.$emit('error', 'Failed to load suppliers');
    } finally {
      this.loadingSuppliers = false;
    }
  }

  private async fetchLocations() {
    this.loadingLocations = true;
    try {
      const locations = new StoreLocationList();
      await locations.fetch();
      this.locations = locations.items;
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      this.loadingLocations = false;
    }
  }

  private getStatusColor(status: string): string {
    switch (status) {
      case 'draft': return 'grey';
      case 'finalized': return 'primary';
      case 'completed': return 'success';
      case 'canceled': return 'error';
      default: return 'grey';
    }
  }

  private formatNumber(value: number): string {
    // return value?.toFixed(2) || '0.00';
    return value.toString();

  }

  private async convertToPO(item: BuyingListModel) {
    this.$router.push(`/purchase-orders/create?from_list=${item.id}`);
  }

  get hasActiveFilters(): boolean {
    return !!(
      this.filters.status ||
      this.filters.supplierId ||
      this.filters.locationId ||
      this.search
    );
  }

  private resetFilter(filterName: string) {
    if (filterName === 'search') {
      this.search = '';
    } else {
      this.filters[filterName as keyof typeof this.filters] = null;
    }
    this.fetchData();
  }

  private resetAllFilters() {
    this.filters = {
      status: null,
      supplierId: null,
      locationId: null
    };
    this.search = '';
    this.fetchData();
  }

  private createList() {
    if (this.selectedSupplierId) {
      this.$router.push(`/suppliers/${this.selectedSupplierId}/buying-lists/create`);
      this.showSupplierDialog = false;
      this.selectedSupplierId = null;
    }
  }
}
