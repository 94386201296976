
import { Component, Vue, Prop } from 'vue-property-decorator';
import { format } from 'date-fns';

interface ITableHeader {
  text: string;
  value: string;
  sortable?: boolean;
}

@Component
export default class SupplierSelection extends Vue {
  @Prop({ required: true }) private readonly suppliers!: any[];

  private search: string = '';

  private readonly headers: ITableHeader[] = [
    { text: 'Supplier Name', value: 'name' },
    { text: 'Products', value: 'numberOfProducts' },
    { text: 'Lead Time', value: 'averageLeadTime' },
    { text: 'Low Stock Items', value: 'lowStockProducts' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  private get recommendedSuppliers() {
    return this.suppliers
      .filter(s => s.recommendation)
      .sort((a, b) => this.getPriorityValue(b.recommendation.priority) - this.getPriorityValue(a.recommendation.priority));
  }

  private getPriorityValue(priority: string): number {
    const values: Record<string, number> = {
      high: 3,
      medium: 2,
      low: 1,
    };
    return values[priority] || 0;
  }

  private getPriorityColor(priority: string): string {
    const colors: Record<string, string> = {
      high: 'error',
      medium: 'warning',
      low: 'success',
    };
    return colors[priority] || 'grey';
  }

  private formatNumber(value: number): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  private formatDate(date: string): string {
    return format(new Date(date), 'MMM d, yyyy');
  }

  private selectSupplier(supplier: any): void {
    this.$emit('select', supplier);
  }
}
