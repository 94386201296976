
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IReorderFormula } from '@/models/sourcing_strategy';


type ComponentType = 'metric' | 'operator' | 'value';

interface MetricItem {
  text: string;
  value: string;
}

interface FormulaComponent {
  type: ComponentType;
  value: string | number;
  label?: string;
}

interface Colors {
  [key: string]: string;
  metric: string;
  operator: string;
  value: string;
}

@Component
export default class ReorderFormulaBuilder extends Vue {
  @Prop({ type: Array, required: true }) readonly availableMetrics!: MetricItem[];
  
  private formulaComponents: FormulaComponent[] = [];
  private selectedMetric = '';
  private selectedOperator = '';
  private customValue: number | null = null;
  @Prop({ type: Object, default: () => ({
    type: 'default',
    components: [],
    formula: ''
  }) }) readonly initFormula!: IReorderFormula;

  created() {
    // Initialize formula components from initFormula
    if (this.initFormula && this.initFormula.components.length > 0) {
      this.formulaComponents = [...this.initFormula.components];
    }
  }

  @Watch('initFormula', { deep: true })
  private onInitFormulaChange(newFormula: IReorderFormula) {
    if (newFormula && newFormula.components.length > 0) {
      this.formulaComponents = [...newFormula.components];
    }
  }

  private operators = [
    { text: 'Add (+)', value: '+' },
    { text: 'Subtract (-)', value: '-' },
    { text: 'Multiply (*)', value: '*' },
    { text: 'Divide (/)', value: '/' },
    { text: 'Open Parenthesis', value: '(' },
    { text: 'Close Parenthesis', value: ')' }
  ];

  @Watch('formulaComponents', { deep: true })
  private onFormulaChange() {
    this.$emit('input', {
      components: this.formulaComponents,
      formula: this.formulaPreview
    });
  }

  get formulaPreview(): string {
    return this.formulaComponents
      .map(component => component.value)
      .join(' ');
  }

  private getComponentColor(type: string): string {
    const colors: Colors = {
      metric: 'primary',
      operator: 'secondary',
      value: 'info'
    };
    return colors[type] || 'default';
  }

  private getComponentLabel(component: FormulaComponent): string {
    if (component.type === 'metric') {
      const metric = this.availableMetrics.find(m => m.value === component.value);
      return metric ? metric.text : String(component.value);
    }
    return String(component.value);
  }

  private addMetric(value: string) {
    if (value) {
      this.formulaComponents.push({
        type: 'metric',
        value
      });
      this.selectedMetric = '';
    }
  }

  private addOperator(value: string) {
    if (value) {
      this.formulaComponents.push({
        type: 'operator',
        value
      });
      this.selectedOperator = '';
    }
  }

  private addCustomValue(): void {
    if (this.customValue !== null) {
      this.formulaComponents.push({
        type: 'value',
        value: this.customValue
      });
      this.customValue = null;
    }
  }

  private removeComponent(index: number) {
    this.formulaComponents.splice(index, 1);
  }

  private insertDefaultFormula(): void {
    const defaultFormula = [
      { type: 'operator', value: '(' },
      { type: 'metric', value: 'avg_daily_demand' },
      { type: 'operator', value: '*' },
      { type: 'metric', value: 'lead_time' },
      { type: 'operator', value: ')' },
      { type: 'operator', value: '+' },
      { type: 'metric', value: 'safety_stock' },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'incoming_inventory' },
      { type: 'operator', value: '-' },
      { type: 'metric', value: 'inventory_on_hand' }
    ] as FormulaComponent[];

    this.formulaComponents = defaultFormula;
  }
}
