export const buyingListsData = {
  activeBuyingLists: [
    {
      id: 1,
      name: "Weekly Restock - June W1",
      createdAt: "2024-06-01",
      status: "In Progress",
      totalItems: 45,
      totalValue: 12500,
      lastUpdated: "2024-06-02"
    },
    {
      id: 2,
      name: "Summer Collection Pre-order",
      createdAt: "2024-05-28",
      status: "Draft",
      totalItems: 32,
      totalValue: 8750,
      lastUpdated: "2024-05-30"
    },
    {
      id: 3,
      name: "Seasonal Restocking",
      createdAt: "2024-05-25",
      status: "Pending Review",
      totalItems: 28,
      totalValue: 6300,
      lastUpdated: "2024-05-29"
    }
  ]
}; 