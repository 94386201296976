
import { defineComponent, ref, watch } from 'vue';
import router from '@/router';
import { SupplierModel } from '@/models/supplier';

export default defineComponent({
  name: 'SupplierCreate',

  setup() {
    const form = ref(null);
    const isValid = ref(false);
    const loading = ref(false);
    const showError = ref(false);
    const errorMessage = ref('');

    const supplier = ref(new SupplierModel({
      name: '',
      contactEmail: '',
      contactPhone: '',
      address: '',
      minOrderValue: 0,
      maxOrderValue: 0,
      minInventoryValue: 0,
      maxInventoryValue: 0,
      leadTime: 0,
      reorderCycle: 0,
      status: 'INACTIVE',
      summaryMetrics: []
    }));

    const isActive = ref(false);

    watch(isActive, (value) => {
      supplier.value.status = value ? 'ACTIVE' : 'INACTIVE';
    });

    const nameRule = (v: string) => !!v || 'Name is required';
    const emailRules = [
      (v: string) => !!v || 'Email is required',
      (v: string) => /.+@.+\..+/.test(v) || 'Email must be valid'
    ];
    const phoneRule = (v: string) => !v || /^\+?[\d\s-]+$/.test(v) || 'Phone number must be valid';
    const requiredNumberRule = (v: number) => v !== null && v !== undefined || 'Field is required';
    const positiveNumberRule = (v: number) => v >= 0 || 'Value must be positive';
    const maxValueRule = (v: number) => v >= supplier.value.minOrderValue || 'Must be greater than minimum order value';
    const maxInventoryValueRule = (v: number) => 
      v >= supplier.value.minInventoryValue || 'Must be greater than minimum inventory value';

    const handleSubmit = async () => {
      if (!isValid.value) return;

      loading.value = true;
      try {
        await supplier.value.save();
        router.push({
          name: 'supplierDetail',
          params: { id: supplier.value.id.toString() }
        });
      } catch (error: unknown) {
        showError.value = true;
        errorMessage.value = error instanceof Error ? error.message : 'Failed to create supplier';
      } finally {
        loading.value = false;
      }
    };

    const handleCancel = () => {
      router.push({ name: 'suppliers' });
    };

    return {
      form,
      isValid,
      loading,
      supplier,
      isActive,
      showError,
      errorMessage,
      handleSubmit,
      handleCancel,
      nameRule,
      emailRules,
      phoneRule,
      requiredNumberRule,
      positiveNumberRule,
      maxValueRule,
      maxInventoryValueRule
    };
  }
});
