
import { Component, Vue } from 'vue-property-decorator';
import { supplierData, supplierProducts } from '@/mock/supplier-data';
import { replenishmentMetrics } from '@/mock/replenishment-metrics';
import { buyingListsData } from '@/mock/buying-lists';
import SupplierSelection from '@/components/supplier/SupplierSelection.vue';
import ReviewProducts from '@/components/product/ReviewProducts.vue';
import SaveBuyingListDialog from '@/components/buying-list/SaveBuyingListDialog.vue';
import BuyingListDetail from '@/components/buying-list/BuyingListDetail.vue';


interface Product {
  id: number;
  name: string;
  sku: string;
  currentStock: number;
  recommendedReorderQuantity: number;
  unitCost: number;
  reorderQuantity: number;
}

@Component({
  components: {
    SupplierSelection,
    ReviewProducts,
    SaveBuyingListDialog,
    BuyingListDetail,
  }
})
export default class SupplierReplenishment extends Vue {
  private currentStep = 'supplier-selection';
  private showTaskSelection = false;
  private suppliers = supplierData.suppliers;
  private products = supplierProducts[1];
  private selectedSupplier: any = null;
  private metrics = replenishmentMetrics.summary;
  private quickStats = replenishmentMetrics.quickStats;
  private selectedBuyingList: any = null;
  private showSaveDialog = false;

  private selectTask(task: any) {
    if (task.id === 'vendor-specific') {
      this.currentStep = 'supplier-selection';
    } else {
      // Handle other task types
      console.log(`Selected task: ${task.name}`);
    }
  }

  private productHeaders = [
    { text: 'Product Name', value: 'name' },
    { text: 'SKU', value: 'sku' },
    { text: 'Quantity', value: 'reorderQuantity' },
    { text: 'Unit Cost', value: 'unitCost' },
    { text: 'Total', value: 'total' },
    { text: 'Actions', value: 'actions', sortable: false }
  ];

  get selectedProducts() {
    return this.products.filter((p: any) => p.reorderQuantity > 0);
  }

  get hasOrderIssues() {
    return this.selectedProducts.some((p: any) => p.reorderQuantity < (this.selectedSupplier?.moq || 0));
  }

  private onSupplierSelect(supplier: any) {
    this.selectedSupplier = supplier;
    this.currentStep = 'review-products';
  }

  private selectAllProducts() {
    this.products = this.products.map((p: any) => ({
      ...p,
      reorderQuantity: p.recommendedReorderQuantity
    }));
  }

  private proceedToBuyingList(updatedProducts: any[]) {
    this.selectedBuyingList = null; // Reset when creating new
    this.products = updatedProducts;
    this.currentStep = 'buying-list';
  }

  private saveBuyingList(saveData: any) {
    if (saveData.type === 'new') {
      console.log('Saving as new buying list:', {
        name: saveData.data.name,
        products: this.selectedProducts
      });
    } else {
      console.log('Merging with existing buying list:', {
        id: saveData.data.id,
        products: this.selectedProducts
      });
    }
    // Here you would typically make an API call to save the data
  }

  private sendBuyingList() {
    // Mock send functionality
    console.log('Sending buying list:', this.selectedProducts);
  }

  private convertToPO() {
    // Mock convert functionality
    console.log('Converting to PO:', this.selectedProducts);
  }

  private formatNumber(value: number): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  private viewExistingBuyingList(buyingList: any) {
    // Find the products associated with this buying list
    // In a real app, this would come from an API
    const buyingListProducts = this.products.map((p: any) => ({
      ...p,
      reorderQuantity: p.recommendedReorderQuantity
    })) || [];

    this.selectedBuyingList = buyingList;
    this.products = buyingListProducts;
    this.currentStep = 'buying-list';
  }

  private updateProducts(updatedProducts: any[]) {
    this.products = updatedProducts as any[];
  }

  private handleSupplierSettingsUpdate(settings: any) {
    // Update the selected supplier with new settings
    this.selectedSupplier = {
      ...this.selectedSupplier,
      ...settings
    };
    // Optionally show success message
    this.$emit('show-snackbar', 'Supplier settings updated successfully');
  }

  private updateAndProceed(updatedProducts: any[]): void {
    this.products = updatedProducts as any[];
    this.proceedToBuyingList(updatedProducts);
  }

  private handleViewStrategy(strategyData: { id: string; products: number[] }) {
    // Filter the products based on the strategy's product IDs
    const strategyProducts = this.products.filter((p: any) => 
      strategyData.products.includes(p.id)
    );
    
    // Update the products list with the filtered products
    this.products = strategyProducts;
    
    // Set recommended quantities
    this.selectAllProducts();
    
    // Move to the buying list step
    this.currentStep = 'buying-list';
  }

  private handleViewAllStrategies() {
    // You can implement navigation to a full strategy listing page
    this.$router.push('/replenishment/strategies');
  }
}
