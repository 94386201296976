import { get, post, put } from '@/services/http';
import { UserPackageModel } from '@/models/user_package';
import { RuleList } from '@/collections/rules';
import { RuleModel } from '@/models/rule';
import { ResourceList } from '@/collections/resources';
import { EtlStatusList } from '@/collections/etl_statuses';
import { EventBus } from '@/main';

interface ResourceCount {
  resource: string;
  modelCount: number;
  apiCount: number;
  startTime?: string;
  endTime?: string;
  limitStartTime?: string;
}

export class StoreModel {
  public id: number;
  public shopName: string;
  public slackConnected: boolean;
  public slackData: any;
  public etlStatus: string;
  public lastEtlTime: string;
  public passedInstallingFlow: string;
  public channelConnected: boolean;
  public channelData: any;
  public askedFirstQuestion: boolean;
  public merchantAppVersion: string;
  public systemAppVersion: string;
  public enableAthena: boolean;
  public isAthenaReady: boolean;
  public timeRangeLimit: string[];
  public information: any;
  public isAthenaFinished: boolean;
  public scheduleEtl: string;
  public isLockedReports: string;
  public installedAt: string;
  public hasSchemas: boolean;
  public config: any;
  public firstOrderAt: string;
  public firstOrderUpdatedAt: string;
  public offerAt: number;
  public currentPackage: UserPackageModel;
  public storeType?: string;
  public limitHistoryDate?: string;
  public rules: RuleList;
  public fullRules: RuleList;
  public resources: ResourceList = new ResourceList();
  public etlStatuses: EtlStatusList = new EtlStatusList();
  public currentStep: number = 0;
  public orderCount: ResourceCount = {
    resource: '',
    modelCount: 0,
    apiCount: 0,
  };
  public productCount: ResourceCount = {
    resource: '',
    modelCount: 0,
    apiCount: 0,
  };
  public customerCount: ResourceCount = {
    resource: '',
    modelCount: 0,
    apiCount: 0,
  };
  public locationCount: ResourceCount = {
    resource: '',
    modelCount: 0,
    apiCount: 0,
  };
  public inventoryItemCount: ResourceCount = {
    resource: '',
    modelCount: 0,
    apiCount: 0,
  };
  public customCollection: ResourceCount = {
    resource: '',
    modelCount: 0,
    apiCount: 0,
  };
  public smartCollection: ResourceCount = {
    resource: '',
    modelCount: 0,
    apiCount: 0,
  };

  constructor(data: any = {}) {
    this.id = data.id;
    this.shopName = data.shopName;
    this.slackConnected = data.slackConnected;
    this.slackData = data.slackData;
    this.etlStatus = data.etlStatus;
    this.lastEtlTime = data.lastEtlTime;
    this.passedInstallingFlow = data.passedInstallingFlow;
    this.channelConnected = data.channelConnected;
    this.channelData = data.channelData;
    this.askedFirstQuestion = data.askedFirstQuestion;
    this.merchantAppVersion = data.merchantAppVersion;
    this.systemAppVersion = data.systemAppVersion;
    this.enableAthena = data.enableAthena;
    this.isAthenaReady = data.isAthenaReady;
    this.isAthenaFinished = data.isAthenaFinished;
    this.timeRangeLimit = [];
    this.information = {};
    this.scheduleEtl = 'daily';
    this.isLockedReports = 'available';
    this.hasSchemas = false;
    this.installedAt = data.installedAt;
    this.config = data.config;
    this.firstOrderAt = data.firstOrderAt;
    this.firstOrderUpdatedAt = data.firstOrderUpdatedAt;
    this.offerAt = 0;
    this.currentPackage = new UserPackageModel();
    this.rules = new RuleList();
    this.fullRules = new RuleList();
    this.resources = new ResourceList();
  }

  public async fetch() {

    const preload = [];
    if (!this.currentPackage.id) {
      preload.push(this.currentPackage.getCurrent());
      preload.push(this.getRules());
      preload.push(this.resources.fetch());
    }
    Promise.all(preload);

    if (!this.isAthenaFinished) {
      await this.getStoreData();
    }
  }

  public async loadStoreType() {
    if (!this.storeType) {
      const storeTypeData: any = await get(`/stores/type`);
      this.storeType = storeTypeData.data.type;
    }
  }

  public async getStoreData() {
    const res: any = await get(`/stores/detail`);
    await this.mapData(res.data);
  }

  public async sendEvent(event: string, data: any = {}) {
    const res: any = await post('/customerio/events', {
      name: event,
      data,
    });
  }

  public async startOffer(offerAt: number) {
    const res: any = await post('/stores/offer', {
      offerAt,
    });
  }

  public async confirmUnlockReports() {
    if (this.isLockedReports !== 'confirming') {
      return;
    }
    await put(`/stores/confirm_unlock_reports`);
    this.isLockedReports = 'available';
  }

  public async saveSettings() {
    await put(`/settings`, {
      contactName: this.config.contactName,
      contactEmail: this.config.contactEmail,
      phone: this.config.contactPhone,
      productCategory: this.config.productCategory,
      topReports: this.config.topReports,
      role: this.config.role,
      purpose: this.config.purpose,
      metrics: this.config.metrics,
      profile: this.config.profile,
      favoriteReports: this.config.favoriteReports,
      homepageDashboardId: this.config.homepageDashboardId,
      homepageDashboardType: this.config.homepageDashboardType,
      confirmRightReport: this.config.confirmRightReport + '',
      confirmCorrectData: this.config.confirmCorrectData + '',
      instantMessageChannel: this.config.instantMessageChannel,
      instantMessageAccount: this.config.instantMessageAccount,
      completeReplenishmentSetting: this.config.completeReplenishmentSetting + '',
      chatChannels: this.config.chatChannels,
    });
  }

  public async saveLanguage() {
    await put(`/settings/language`, {
      language: (this.config) ? this.config.language || 'en' : 'en',
    });
  }

  public async updateInstallationStatus() {
    await put(`/stores/${this.id}/installing_status`);
    this.passedInstallingFlow = 'passed';
  }

  public async getRules() {
    this.rules = new RuleList();
    const res: any = await get(`/store/rules`);
    if (res.data) {
      this.rules.mapData(res.data.rules);
      this.fullRules.mapData(res.data.fullRules);
    }
  }

  public async mapData(data: any = {}) {
    this.id = data.id;
    this.shopName = data.shop_name;
    this.slackConnected = data.slack_connected;
    this.slackData = data.slack_data;
    this.etlStatus = data.etl_status;
    this.passedInstallingFlow = data.passed_installing_flow;
    this.channelConnected = data.channel_connected;
    this.channelData = data.channel_data;
    this.askedFirstQuestion = data.asked_first_question;
    this.merchantAppVersion = data.merchant_app_version;
    this.systemAppVersion = data.system_app_version;
    this.enableAthena = data.enable_athena;
    this.information = data.information;
    this.scheduleEtl = data.schedule_etl;
    this.isLockedReports = data.is_locked_reports;
    this.installedAt = data.installed_at;
    this.hasSchemas = data.has_schemas === 'created';
    this.config = data.config;
    this.lastEtlTime = data.endTime;
    this.firstOrderAt = data.firstOrderAt;
    this.firstOrderUpdatedAt = data.firstOrderUpdatedAt;
    this.timeRangeLimit = [data.startTime, data.endTime];
    this.isAthenaReady = this.timeRangeLimit[0] !== this.timeRangeLimit[1];
    this.offerAt = Number(data.offerAt) || 0;
    this.limitHistoryDate = data.limitHistoryDate;
    this.etlStatuses = new EtlStatusList();
    this.etlStatuses.mapData(data.etlStatuses);
    this.currentStep = data.currentStep;

    try {
      if (this.passedInstallingFlow !== 'new') {
        await this.checkEtlStatus(data);
      }
    } catch (e) {
      this.isAthenaReady = false;
      this.isAthenaFinished = false;
    }
    if (this.config) {
      this.config.language = this.config.language || localStorage.getItem('language') || 'en';
    }
  }

  public validateRule(trackingResources: any = []) {
    if (this.rules.items.length === 0) {
      return {isValidate: true, resource: ''};
    }

    let result: boolean = true;
    let notValidateResource: string = '';
    for (const trackingResource of trackingResources) {
      for (const resource of this.resources.items) {
        if (resource.code === trackingResource.code) {
          const validateResult = this.isValidateRule(
            resource.ruleCode,
            trackingResource.value || resource.validData
          );
          result = result && validateResult;
          if (!validateResult) {
            notValidateResource = resource.code;
          }
        }
      }
    }

    return {isValidate: result, resource: notValidateResource};
  }

  public isValidateRule(code: string, validData: string = '') {
    if (
      this.id < 7074
      && this.currentPackage.name !== 'Free'
      && this.currentPackage.price > 0
    ) {
      return true;
    }
    let value: string | number = '';
    let isExist = false;
    let rule: RuleModel = new RuleModel();
    for (const item of this.rules.items) {
      if (item.code === code) {
        rule = item;
        isExist = true;
        break;
      }
    }
    value = rule.value;
    if (!isExist) {
      for (const item of this.fullRules.items) {
        if (item.code === code) {
          rule = item;
        }
      }
      value = rule.defaultValue;
    }
    switch (rule.type) {
      case 'number': {
        if (Number(value) === -1) {
          return true;
        } else {
          return Number(validData) - Number(value) < 0;
        }
        break;
      }
      default: {
        return validData === value;
        break;
      }
    }
    return true;
  }

  public saveFavoriteReport(isSave: boolean = true, data: any = {}) {
    const favorites: any = this.config.favoriteReports || [];
    if (isSave) {
      // add the current dashboard to favorite list
      let isExist: boolean = false;
      for (const item of favorites) {
        if (item.type === data.type && item.id === data.id) {
          isExist = true;
        }
      }
      if (!isExist) {
        favorites.push(data);
      }
    } else {
      // remove this current dashboard from favorite list
      let index: number = 0;
      for (const item of favorites) {
        if (item.type === data.type && item.id === data.id) {
          break;
        }
        index++;
      }
      if (index < favorites.length) {
        favorites.splice(index, 1);
      }
    }
    try {
      this.config.favoriteReports = favorites;
      this.saveSettings();
    } catch (e: any) {
      // retry to save
      this.saveSettings();
    }
    EventBus.$emit('update-favorite-report-change-status');
  }

  public getFavoriteStatus(id: number = 0, type: string = '') {
    const favorites: any = this.config.favoriteReports || [];
    for (const item of favorites) {
      if (item.type === type && item.id === id) {
        return true;
      }
    }
    return false;
  }

   public async loadEtlStatus() {
    let res: any;
    const preload = [];
    preload.push(this.resourceCount('orders'));
    preload.push(this.resourceCount('products'));
    preload.push(this.resourceCount('customers'));
    preload.push(this.resourceCount('locations'));
    preload.push(this.resourceCount('inventory_items'));
    preload.push(this.resourceCount('custom_collections'));
    preload.push(this.resourceCount('smart_collections'));
    await Promise.all(preload);
  }



  public async resourceCount(resource: string) {
    let res: any;
    res = await get(`/etl_metrics/count_resource/${resource}`);

    const countData = {
      resource: resource,
      apiCount: res.apiCount,
      modelCount: Math.min(res.modelCount, res.apiCount),
    };

    switch (resource) {
      case 'orders':{
        this.orderCount = countData;
        break;
      }
      case 'products':{
        this.productCount = countData;
        break;
      }
      case 'customers':{
        this.customerCount = countData;
        break;
      }
      case 'locations':{
        this.locationCount = countData;
        break;
      }
      case 'inventory_items':{
        this.inventoryItemCount = countData;
        break;
      }
      case 'custom_collections':{
        this.customCollection = countData;
        break;
      }
      case 'smart_collections':{
        this.smartCollection = countData;
        break;
      }
    }
  }

  public async forceSyncResource(resource: string) {
    const res: any = await get(`/etl/trigger_incremental?resources=${resource}`);
    return res.data;
  }

  public isTrial() {
    //compare the trialEndAt with current date
    return this.currentPackage.trialEndAt && Date.parse(this.currentPackage.trialEndAt + '') > Date.now();
  }

  private async checkEtlStatus(data: any = {}) {
    if (this.id < 3030) {
      this.isAthenaFinished = true;
      this.isAthenaReady = true;
    } else {
      let olapCurrentInventoryLevelsReady = false;
      let orderDailySalesOlapReady = false;
      let salesVariantOlapReady = false;
      let salesOrderVariantOlapReady = false;

      let olapCurrentInventoryLevelsFinished = false;
      let orderDailySalesOlapFinished = false;
      let salesVariantOlapFinished = false;
      let salesOrderVariantOlapFinished = false;

      for (const etlStatus of this.etlStatuses.items) {
        const startTimeStr = etlStatus.startTime ? etlStatus.startTime.split('T')[0] : '';
        const limitStartTimeStr = etlStatus.limitStartTime ? etlStatus.limitStartTime.split('T')[0] : '';
        const startTime = new Date(startTimeStr).setHours(0, 0, 0, 0);
        const limitStartTime = new Date(limitStartTimeStr).setHours(0, 0, 0, 0);
        let readyStartTime = new Date(this.installedAt).setHours(0, 0, 0, 0);
        readyStartTime = new Date(readyStartTime).setMonth(new Date(readyStartTime).getMonth() - 6);

        if (readyStartTime < limitStartTime) {
          readyStartTime = limitStartTime;
        }

        if (etlStatus.resource === 'sales_order_variant_olap') {
          if (startTime <= readyStartTime) {
            salesOrderVariantOlapReady = true;
          }
          if (startTime <= limitStartTime) {
            salesOrderVariantOlapFinished = true;
          }
        }

        if (etlStatus.resource === 'olap_current_inventory_levels') {
          if (etlStatus.lastUpdatedAt) {
            olapCurrentInventoryLevelsReady = true;
            olapCurrentInventoryLevelsFinished = true;
          }
        }
        if (this.currentPackage.price === 0) {
          if (etlStatus.resource === 'products' && etlStatus.etlType === 'transform_request') {
            if (startTime <= limitStartTime) {
              olapCurrentInventoryLevelsReady = true;
              olapCurrentInventoryLevelsFinished = true;
            }
          }
        }
      }

      if (olapCurrentInventoryLevelsReady && salesOrderVariantOlapReady) {
        if (olapCurrentInventoryLevelsFinished && salesOrderVariantOlapFinished) {
          this.isAthenaFinished = true;
        }
        this.isAthenaReady = true;
      } else {
        let res: any;
        res = await get(`/etl_metrics/count_resource/orders`);
        this.orderCount = {
          resource: 'orders',
          apiCount: res.apiCount,
          modelCount: res.modelCount,
        };
        if (this.orderCount.apiCount === 0) {
          salesOrderVariantOlapReady = true;
          salesOrderVariantOlapFinished = true;
        }

        res = await get(`/etl_metrics/count_resource/products`);
        this.productCount = {
          resource: 'products',
          apiCount: res.apiCount,
          modelCount: res.modelCount,
        };
        if (this.productCount.apiCount === 0) {
          olapCurrentInventoryLevelsReady = true;
          olapCurrentInventoryLevelsFinished = true;
        }

        if (olapCurrentInventoryLevelsFinished && salesOrderVariantOlapFinished) {
          this.isAthenaReady = true;
          this.isAthenaFinished = true;
        }
      }
    }
  }

  public async saveMultipleStoreStockCombinationRule(isCombined: boolean) {
    await put(`/multistores/inventory_combine`, {
      combination: isCombined,
    });
  }

  public get canShowOnboardCompleteConfirm() {
    if (this.config.confirmCorrectData !== 'true') {
      return true;
    }
    if (this.config.confirmRightReport !== 'true') {
      return true;
    }
    return false;
  }

  public get assistyPlan() {
    const mappingPlans: any = [
      {
        shopify: 'Plus',
        assisty: 'Shopify Plus',
      },
      {
        shopify: 'Advanced',
        assisty: 'Advanced Shopify',
      },
      {
        shopify: 'Basic',
        assisty: 'Basic Shopify',
      },
      {
        shopify: 'Shopify',
        assisty: 'Professional',
      },
    ];

    const shopifyPlan = this.information.plan_display_name.toLowerCase();
    let assistyPlan = 'Professional';
    for (const item of mappingPlans) {
      if (shopifyPlan.includes(item.shopify.toLowerCase())) {
        assistyPlan = item.assisty;
        break;
      }
    }
    return assistyPlan;
  }

  public async canSubscribePaidPlan() {
    const restrictedPlans = ['developer', 'test'];
    const shopifyPlan = this.information.plan_display_name.toLowerCase();
    const assistyTestStores = ['assisty-demo.myshopify.com', 'dariustest2.myshopify.com', 'the-best-megastore-test.myshopify.com'];
    for (const store of assistyTestStores) {
      if (this.shopName === store) {
        return true;
      }
    }
    for (const plan of restrictedPlans) {
      if (shopifyPlan.includes(plan)) {
        return false;
      }
    }

    // check order count
    await this.resourceCount('orders');
    if (this.orderCount.apiCount === 0) {
      return false;
    }

    return true;
  }

  /**
   * Switch to the store with the given storeId
   * @param storeId 
   * @returns {token: string, store_name: string}
   */
  public async switchStore(storeId: string) {
    const res: any = await get(`/switch_store/${storeId}`);
    return res.data;
  }

  /**
   * Get the list of stores in the same group as the current store
   * @returns {id: string, name: string}[]  
   */
  public async getGroupStores() {
    const res: any = await get(`/store_groups`);
    return res.data;
  }
}
