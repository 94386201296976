
import { Component, Vue } from 'vue-property-decorator';
import ReorderFormulaBuilder from '@/components/strategy/ReorderFormulaBuilder.vue';
import IdentificationMetricsBuilder from '@/components/strategy/IdentificationMetricsBuilder.vue';
import { SourcingStrategyModel } from '@/models/sourcing_strategy';
import { SourcingStrategyList } from '@/collections/sourcing_strategies';
import { availableMetrics, reorderMetrics } from '@/mock/strategy-data';

interface ITableHeader {
  text: string;
  value: string;
  sortable?: boolean;
}

@Component({
  components: {
    ReorderFormulaBuilder,
    IdentificationMetricsBuilder
  }
})
export default class CustomStrategyBuilder extends Vue {
  private currentStep = 1;
  private strategies = new SourcingStrategyList();
  private newStrategy: SourcingStrategyModel = new SourcingStrategyModel();
  private selectedStrategy: SourcingStrategyModel = new SourcingStrategyModel();

  private availableIcons = [
    { text: 'Rocket', value: 'mdi-rocket-launch' },
    { text: 'Alert', value: 'mdi-alert' },
    { text: 'Trending', value: 'mdi-trending-up' },
    { text: 'Cart', value: 'mdi-cart' },
    { text: 'Store', value: 'mdi-store' }
  ];

  private availableColors = [
    { text: 'Primary', value: 'primary' },
    { text: 'Error', value: 'error' },
    { text: 'Success', value: 'success' },
    { text: 'Warning', value: 'warning' },
    { text: 'Info', value: 'info' }
  ];

  // Load metrics from strategy-data
  private availableMetrics = availableMetrics;
  private reorderMetrics = reorderMetrics;

  created() {
    // Load strategy templates
    this.strategies.loadMockStrategies();
  }

  get strategyTemplates() {
    const templateIds = ['high-priority', 'trending', 'seasonal', 'demand-forecast', 'high-value', 'margin-optimization'];
    return this.strategies.items.filter(s => templateIds.includes(s.id));
  }

  private getMetricLabel(value: string): string {
    const metric = this.availableMetrics.find(m => m.value === value);
    return metric ? metric.text : value;
  }

  private selectTemplate(templateId: string | undefined) {
    this.newStrategy = new SourcingStrategyModel();
    
    if (templateId === 'scratch') {
      // Start with empty strategy
      this.newStrategy.title = '';
      this.newStrategy.icon = '';
      this.newStrategy.iconColor = '';
      this.newStrategy.description = '';
      this.newStrategy.identificationMetrics = [];
      this.newStrategy.reorderFormula = {
        type: 'custom',
        components: [],
        formula: ''
      };
    } else if (templateId) {
      // Load template data
      this.selectedStrategy = this.strategies.items.find(s => s.id === templateId) as SourcingStrategyModel;
      if (this.selectedStrategy) {
        this.newStrategy.title = `Custom ${this.selectedStrategy.title}`;
        this.newStrategy.icon = this.selectedStrategy.icon;
        this.newStrategy.iconColor = this.selectedStrategy.iconColor;
        this.newStrategy.description = this.selectedStrategy.description;
        this.newStrategy.category = this.selectedStrategy.category;
        
        // Deep copy identification metrics
        if (this.selectedStrategy.identificationMetrics) {
          this.newStrategy.identificationMetrics = this.selectedStrategy.identificationMetrics.map(metric => ({
            metric: metric.metric,
            comparison: metric.comparison || 'value',
            operator: metric.operator,
            threshold: metric.threshold,
            secondMetric: metric.secondMetric
          }));
        }

        // Deep copy reorder formula
        if (this.selectedStrategy.reorderFormula) {
          this.newStrategy.reorderFormula = {
            type: this.selectedStrategy.reorderFormula.type,
            components: this.selectedStrategy.reorderFormula.components ? [...this.selectedStrategy.reorderFormula.components] : [],
            formula: this.selectedStrategy.reorderFormula.formula
          };
        }
      }
    }
    this.currentStep = 2;
  }

  private async saveStrategy() {
    try {
      // Add the new strategy to the collection
      this.strategies.add(this.newStrategy);
      
      // Navigate to strategy list
      this.$router.push({ name: 'strategyList' });
    } catch (error) {
      console.error('Error saving strategy:', error);
    }
  }

  private getFormulaComponentColor(type: string): string {
    const colors: { [key: string]: string } = {
      metric: 'primary',
      operator: 'secondary',
      value: 'info'
    };
    return colors[type] || 'default';
  }

  private getFormulaComponentLabel(component: { type: string; value: string | number }): string {
    if (component.type === 'metric') {
      const metric = this.reorderMetrics.find(m => m.value === component.value);
      return metric ? metric.text : String(component.value);
    }
    return String(component.value);
  }
}
