
import { Component, Vue } from 'vue-property-decorator';
import ProductSourcingStrategy from '@/components/ProductSourcingStrategy.vue';

interface Metric {
  title: string;
  value: number;
  change: number;
  icon: string;
  color: string;
  trend: 'up' | 'down';
}

@Component({
  components: {
    ProductSourcingStrategy,
  },
})
export default class ReplenishmentCenter extends Vue {
  private metrics: Metric[] = [
    {
      title: 'Low Stock Products',
      value: 24,
      change: 15,
      icon: 'mdi-alert',
      color: 'warning',
      trend: 'up'
    },
    {
      title: 'Out of Stock Products',
      value: 8,
      change: -3,
      icon: 'mdi-package-variant',
      color: 'error',
      trend: 'down'
    },
    {
      title: 'Revenue at Risk',
      value: 12500,
      change: 20,
      icon: 'mdi-currency-usd',
      color: 'error',
      trend: 'up'
    },
    {
      title: 'Pending Orders',
      value: 5,
      change: -2,
      icon: 'mdi-truck-delivery',
      color: 'info',
      trend: 'down'
    }
  ];

  private formatNumber(value: number): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  private handleViewStrategy(strategyData: { id: string; products: number[] }) {
    this.$router.push({
      name: 'strategyDetail',
      params: { strategyId: strategyData.id }
    });
  }

  private handleViewAllStrategies() {
    this.$router.push('/replenishment/strategies');
  }

  private goToSupplierReplenishment() {
    this.$router.push('/supplier-replenishment');
  }

  private getChipColor(value: number, trend: 'up' | 'down'): string {
    if (trend === 'up') {
      return value > 10 ? 'error' : 'success';
    }
    return value > 10 ? 'warning' : 'success';
  }
}
