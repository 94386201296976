
import { Component, Vue, Prop } from 'vue-property-decorator';
import SaveBuyingListDialog from './SaveBuyingListDialog.vue';
import SendBuyingListDialog from './SendBuyingListDialog.vue';
import ConvertToPODialog from './ConvertToPODialog.vue';

interface IProduct {
  id: string | number;
  name: string;
  sku: string;
  unitCost: number;
  reorderQuantity: number;
}

@Component({
  components: {
    SaveBuyingListDialog,
    SendBuyingListDialog,
    ConvertToPODialog
  }
})
export default class BuyingListDetail extends Vue {
  @Prop({ required: true }) products!: any[];
  @Prop({ required: true }) supplier!: any;
  @Prop({ default: null }) buyingList!: any;

  private isEditMode = false;
  private showSaveDialog = false;
  private showSendDialog = false;
  private showConvertDialog = false;
  private localProducts: IProduct[] = [];

  get isExistingList() {
    return !!this.buyingList;
  }

  private baseHeaders = [
    { text: 'Product Name', value: 'name' },
    { text: 'SKU', value: 'sku' },
    { text: 'Unit Cost', value: 'unitCost' },
    { text: 'Quantity', value: 'reorderQuantity' },
    { text: 'Total', value: 'total' }
  ];

  private editHeaders = [
    ...this.baseHeaders,
    { text: 'Actions', value: 'actions', sortable: false }
  ];

  get currentHeaders() {
    return this.isEditMode ? this.editHeaders : this.baseHeaders;
  }

  created() {
    this.localProducts = [...this.products];
  }

  get totalOrderValue() {
    return this.localProducts.reduce((total, product) => {
      return total + (product.reorderQuantity * product.unitCost);
    }, 0);
  }

  get hasOrderIssues() {
    const belowMOQ = this.localProducts.some(p => 
      p.reorderQuantity < (this.supplier.moq || 0)
    );
    
    const belowMinOrderValue = this.totalOrderValue < 
      (this.supplier.minOrderValue || 0);

    return belowMOQ || belowMinOrderValue;
  }

  private updateQuantity(item: any) {
    // Ensure quantity is not negative
    item.reorderQuantity = Math.max(0, item.reorderQuantity);
    this.$emit('update', this.localProducts);
  }

  private removeItem(item: IProduct): void {
    this.localProducts = this.localProducts.filter((p: IProduct) => p.id !== item.id);
    this.$emit('update', this.localProducts);
  }

  private save() {
    // For existing list, just save changes
    this.$emit('save', {
      type: 'update',
      data: {
        id: this.buyingList.id,
        products: this.localProducts
      }
    });
  }

  private onSaveNewList(saveData: any) {
    // For new list, pass through the save dialog data
    this.$emit('save', {
      ...saveData,
      products: this.localProducts
    });
  }

  private formatNumber(value: number): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  private onSend(sendData: any) {
    this.$emit('send', {
      ...sendData,
      products: this.localProducts
    });
  }

  private onConvert(convertData: any) {
    this.$emit('convert', {
      ...convertData,
      products: this.localProducts
    });
  }
}
