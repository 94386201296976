
import { Component, Vue } from 'vue-property-decorator';
import ReplenishmentCenter from '@/components/ReplenishmentCenter.vue';

@Component({
  components: {
    ReplenishmentCenter
  }
})
export default class ReplenishmentCenterView extends Vue {}
