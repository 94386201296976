
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SendBuyingListDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  private value!: boolean;

  @Prop({ required: true })
  private supplier!: any;

  @Prop({ default: null })
  private buyingList!: any;

  private isValid = false;
  private isSending = false;
  private recipients: string[] = [];
  private subject = '';
  private message = '';
  private attachPDF = true;
  private attachExcel = false;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get suggestedRecipients() {
    // Mock data - in real app, this would come from supplier contacts
    return [
      'supplier@example.com',
      'purchasing@example.com',
      'manager@example.com'
    ];
  }

  created() {
    // Pre-fill supplier email if available
    if (this.supplier?.email) {
      this.recipients = [this.supplier.email];
    }

    // Pre-fill subject based on buying list
    this.subject = this.buyingList 
      ? `Updated Buying List - ${this.buyingList.name}`
      : `New Buying List - ${this.supplier.name}`;

    // Pre-fill message
    this.message = `Please review the attached buying list for ${this.supplier.name}.\n\nRegards,\n[Your Name]`;
  }

  private removeRecipient(item: string) {
    this.recipients = this.recipients.filter(r => r !== item);
  }

  private async send() {
    this.isSending = true;
    try {
      // Mock API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      this.$emit('send', {
        recipients: this.recipients,
        subject: this.subject,
        message: this.message,
        attachments: {
          pdf: this.attachPDF,
          excel: this.attachExcel
        }
      });
      
      this.closeDialog();
    } finally {
      this.isSending = false;
    }
  }

  private closeDialog() {
    this.show = false;
    this.resetForm();
  }

  private resetForm() {
    this.recipients = [];
    this.subject = '';
    this.message = '';
    this.attachPDF = true;
    this.attachExcel = false;
    if (this.$refs.form) {
      (this.$refs.form as any).reset();
    }
  }
}
