
import { Component, Vue, Prop } from 'vue-property-decorator';
import { buyingListsData } from '@/mock/buying-lists';

@Component
export default class SaveBuyingListDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  private value!: boolean;

  private saveOption = 'new';
  private newListName = '';
  private selectedExistingList = null;
  private existingLists = buyingListsData.activeBuyingLists;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get isValid() {
    if (this.saveOption === 'new') {
      return !!this.newListName;
    }
    return !!this.selectedExistingList;
  }

  private closeDialog() {
    this.show = false;
    this.resetForm();
  }

  private save() {
    const saveData = {
      type: this.saveOption,
      data: this.saveOption === 'new' 
        ? { name: this.newListName }
        : { id: this.selectedExistingList }
    };
    
    this.$emit('save', saveData);
    this.closeDialog();
  }

  private resetForm() {
    this.saveOption = 'new';
    this.newListName = '';
    this.selectedExistingList = null;
  }
}
