
import { Component, Vue } from 'vue-property-decorator';
import StrategyDetailComponent from '@/components/strategy/StrategyDetailComponent.vue';
import { IProduct } from '@/models/sourcing_strategy';

@Component({
  components: {
    StrategyDetailComponent,
  }
})
export default class StrategyDetail extends Vue {
  private handleProductUpdate(products: IProduct[]): void {
    // Handle product updates
    console.log('Products updated:', products);
  }
}
