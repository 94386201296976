
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SupplierModel } from '@/models/supplier';
import { BuyingLists } from '@/collections/buying_lists';
import { StoreModel } from "@/models/store";
import { ReportModel } from "@/models/report";
import { MeasureModel } from "@/models/measure";
import { DimensionModel } from "@/models/dimension";
import { AnalyticsTypeModel } from "@/models/analytics_type";
import { ParamModel } from "@/models/param";
import { FilterColumnList } from "@/collections/filter_columns";
import { ParamList } from "@/collections/params";
import { MeasureList } from "@/collections/measures";
import ReportFilter from "@/components/ReportFilter.vue";
import { InventoryParam } from "@/models/inventory_param";
import { ProductSegmentModel } from "@/models/product_segment";
import { FilterColumnModel } from "@/models/filter_column";
import { FilterDimensionList } from "@/collections/filter_dimensions";
@Component({
  components: {
    ReportFilter
  }
})
export default class SupplierDetailView extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  private supplier: SupplierModel | null = null;
  private buyingLists = new BuyingLists();
  private loading = false;
  private saving = false;
  private activeTab = 'overview';
  private products: any[] = [];
  private editedSupplier: SupplierModel = new SupplierModel(null);
  private addProductDialog = false;
  private productSearch = '';
  private selectedProducts: any[] = [];
  private availableProducts: any[] = [];
  private confirmUnlinkDialog = false;
  private productToUnlink: any = null;
  private settingsValid = true;
  private statusOptions = ['ACTIVE', 'INACTIVE', 'SUSPENDED'];
  private loadingProduct: boolean = false;
  private loadingAvailableProduct: boolean = false;
  private addingProducts: boolean = false;
  private reportColumns: any[] = [];
  private filterData: any = {
    dimension: new DimensionModel(),
    analyticsType: new AnalyticsTypeModel(),
    dates: [],
    filterColumns: new FilterColumnList(),
    reportParams: new ParamList(),
    productSegment: new ProductSegmentModel(),
  };
  private measure: MeasureModel = new MeasureModel({code: 'replenishment_settings'});
  private dimension: DimensionModel = new DimensionModel({code: 'by_variant'});
  private filterColumns: FilterColumnList = new FilterColumnList();

  private get isActive(): boolean {
    return this.editedSupplier.status === 'ACTIVE';
  }

  private set isActive(value: boolean) {
    this.editedSupplier.status = value ? 'ACTIVE' : 'INACTIVE';
  }

  private handleStatusChange(value: boolean): void {
    this.editedSupplier.status = value ? 'ACTIVE' : 'INACTIVE';
  }

  public get filterReportColumns() {
    let reportColumns: any = [];
    if (this.measure.filterDimensions.size() > 0) {
      for (const dim of this.measure.filterDimensions.toArray()) {
        reportColumns.push({
          name: dim.label ? dim.label : dim.name,
          code: dim.fieldCode,
          dataType: dim.dataType,
          luisMapping: dim.code,
        });
      }
    }

    for (const column of this.reportColumns) {
      reportColumns.push(column);
    }
    
    reportColumns = this.refineFilterColumns(reportColumns);
    return reportColumns;
  }

  public refineFilterColumns(reportColumns: any) {
    const duplicateColumns: any = {
      "Item[Item Category]": "product_collections",
      product_tag: "product_tags",
      order_tag: "order_tags",
      customer_tag: "customer_tags",
    };

    for (const column of reportColumns) {
      if (Object.keys(duplicateColumns).includes(column.code)) {
        let index: number = 0;
        for (const column2 of reportColumns) {
          if (column2.code === duplicateColumns[column.code]) {
            reportColumns.splice(index, 1);
            break;
          }
          index++;
        }
      }
    }
    return reportColumns;
  }

  // Add validation rules
  private emailRules = [
    (v: string) => !!v || 'Email is required',
    (v: string) => /.+@.+\..+/.test(v) || 'Email must be valid'
  ];
  private phoneRule = (v: string) => !v || /^\+?[\d\s-]+$/.test(v) || 'Phone number must be valid';
  private requiredNumberRule = (v: number) => !!v || 'Field is required';
  private positiveNumberRule = (v: number) => v >= 0 || 'Value must be positive';
  private percentageRule = (v: number) => !v || (v >= 0 && v <= 100) || 'Percentage must be between 0 and 100';
  private maxValueRule = (v: number) => v >= this.editedSupplier.minOrderValue || 'Must be greater than minimum order value';
  private maxInventoryValueRule = (v: number) => v >= this.editedSupplier.minInventoryValue || 'Must be greater than minimum inventory value';
  private requiredRule = (v: string) => !!v || 'Field is required';
  private productHeaders = [
    { text: 'Id', value: 'variant_id', sortable: false, width: '100px' },
    { text: 'Product', value: 'Item[Item Description]' },
    { text: 'Variant', value: 'variant' },
    { text: 'SKU', value: 'product_sku' },
    { text: 'Barcode', value: 'product_barcode' },
    { text: 'Vendor', value: 'Vendor Name' },
    { text: 'Supplier', value: 'supplier' },
    { text: 'Lead time', value: '[vendor_lead_time]' },
    { text: 'Unit Cost', value: '[variant_unit_cost]' },
    { text: 'Actions', value: 'actions', sortable: false, width: '100px' }
  ];

  private buyingListHeaders = [
    { text: 'Name', value: 'name' },
    { text: 'Status', value: 'status', width: '120px' },
    { text: 'Total Value', value: 'total_value', width: '120px' },
    { text: 'Items', value: 'items.length', width: '80px' },
    { text: 'Actions', value: 'actions', sortable: false, width: '100px' }
  ];

  private availableProductHeaders = [
    { text: 'Id', value: 'variant_id', sortable: false, width: '100px' },
    { text: 'Product', value: 'Item[Item Description]' },
    { text: 'Variant', value: 'variant' },
    { text: 'SKU', value: 'product_sku' },
    { text: 'Barcode', value: 'product_barcode' },
    { text: 'Vendor', value: 'Vendor Name' },
    { text: 'Supplier', value: 'supplier' },
    { text: 'Lead time', value: '[vendor_lead_time]' },
    { text: 'Unit Cost', value: '[variant_unit_cost]' },
  ];

  async created() {
    await this.fetchSupplier();
    await Promise.all([
      this.fetchProducts(),
      this.fetchBuyingLists()
    ]);
  }

  public async fetchProducts() {
    this.loadingProduct = true;
    try {
      if (this.supplier && this.supplier.id) {
        const reportModel = await this.supplier.fetchProducts('replenishment_settings', 'eq');
        this.reportColumns = reportModel.getReportColumnList();
        this.products = reportModel.getReportRowsData();
        
        this.loadingProduct = false;

        this.loadingAvailableProduct = true;
        const reportModel2 = await this.supplier.fetchProducts('replenishment_settings', 'ne');
        this.availableProducts = reportModel2.getReportRowsData();
        this.loadingAvailableProduct = false;
      }
    } catch (error) {
      console.error('Error fetching supplier products:', error);
      this.$emit('error', 'Failed to load supplier products');
    } finally {
      this.loadingProduct = false;
    }
  }

  private async fetchBuyingLists() {
    this.loading = true;
    try {
      await this.buyingLists.fetchBySupplier(this.supplier!.id);
    } catch (error) {
      console.error('Error fetching buying lists:', error);
      this.$emit('error', 'Failed to load buying lists');
    } finally {
      this.loading = false;
    }
  }

  private async fetchSupplier() {
    
    this.loading = true;
    try {
      const { id } = this.$route.params;
      if (!id) {
        throw new Error('Supplier ID is required');
      }

      // Create new supplier model and fetch details
      this.supplier = new SupplierModel({ id: parseInt(id, 10) });
      await this.supplier.getSupplierDetails();
      // Create a copy for editing
      this.editedSupplier = new SupplierModel(this.supplier);
    } catch (error) {
      console.error('Error fetching supplier details:', error);
      this.$emit('error', 'Failed to load supplier details');
    } finally {
      this.loading = false;
    }
  }

  private formatNumber(value: number | undefined): string {
    if (!value) return '0';
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  private getStatusColor(metrics: any[] | undefined): string {
    if (!metrics) return 'grey';
    const fillRate = metrics.find(m => m.label === 'Order Fill Rate');
    if (!fillRate) return 'grey';
    const value = parseFloat(fillRate.value);
    if (value >= 95) return 'success';
    if (value >= 90) return 'warning';
    return 'error';
  }

  private getSupplierStatus(metrics: any[] | undefined): string {
    if (!metrics) return 'Unknown Performance';
    const fillRate = metrics.find(m => m.label === 'Order Fill Rate');
    if (!fillRate) return 'Unknown Performance';
    const value = parseFloat(fillRate.value);
    if (value >= 95) return 'Excellent';
    if (value >= 90) return 'Good';
    return 'Needs Improvement';
  }

  private getMetricTrendColor(metric: any): string {
    if (!metric.trend) return 'grey';
    return metric.trend.isPositive ? 'success' : 'error';
  }

  private getMetricTrendIcon(metric: any): string {
    if (!metric.trend) return 'mdi-minus';
    return metric.trend.isPositive ? 'mdi-arrow-up' : 'mdi-arrow-down';
  }

  private getStockLevelColor(item: any): string {
    const stock = item.currentStock;
    if (stock <= 0) return 'error';
    if (stock <= 10) return 'warning';
    return 'success';
  }

  private getProductMetricColor(metrics: any): string {
    if (metrics.demand_variability === 'High') return 'error';
    if (metrics.demand_variability === 'Medium') return 'warning';
    return 'success';
  }

  private getBuyingListStatusColor(status: string): string {
    switch (status) {
      case 'draft':
        return 'grey';
      case 'finalized':
        return 'primary';
      case 'completed':
        return 'success';
      case 'canceled':
        return 'error';
      default:
        return 'grey';
    }
  }

  private createBuyingList() {
    if (!this.supplier) return;
    this.$router.push({
      name: 'createBuyingList',
      params: { supplierId: this.supplier.id.toString() }
    });
  }

  private viewBuyingList(list: any) {
    console.log('list', list);
    this.$router.push('/buying-lists/' + list.id);
  }

  private async saveSettings() {
    if (!this.settingsValid || !this.supplier) return;
    this.saving = true;
    try {
      await this.editedSupplier.update();
      this.$emit('success', 'Settings updated successfully');
    } catch (error) {
      console.error('Error saving settings:', error);
      this.$emit('error', 'Failed to update settings');
    } finally {
      this.saving = false;
    }
  }

  private showAddProductDialog() {
    // Get all unassigned products
    this.selectedProducts = [];
    this.addProductDialog = true;
  }

  private async addSelectedProducts() {
    if (!this.supplier) return;
    
    try {
      // Optimistic UI update - add products to the list immediately
      const newProducts = this.selectedProducts.map(product => ({
        ...product,
        Supplier: this.supplier!.name // Match the filter condition in fetchProducts
      }));
      this.products = [...this.products, ...newProducts];
      this.addingProducts = true;
      await this.supplier.addProducts(this.selectedProducts, this.filterData);
      this.supplier.productCount += this.selectedProducts.length;
      this.addingProducts = false;
      // Refresh product list
      await this.fetchProducts();
      this.$emit('success', 'Products added to supplier successfully');
    } catch (error) {
      console.error('Error adding products:', error);
      await this.fetchProducts();
      this.$emit('error', 'Failed to add products to supplier');
    } finally {
      this.addingProducts = false;
      this.addProductDialog = false;
      this.selectedProducts = [];
    }
  }

  private showUnlinkConfirmation(product: any) {
    this.productToUnlink = product;
    this.confirmUnlinkDialog = true;
  }

  private async confirmUnlinkProduct() {
    if (!this.productToUnlink) return;
    
    this.unlinkProduct(this.productToUnlink);
    this.confirmUnlinkDialog = false;
    this.productToUnlink = null;
  }

  private async unlinkProduct(product: any) {
    if (!this.supplier) return;
    
    try {
      // Remove the product from the local state
      this.products = this.products.filter(p => p.variant_id !== product.variant_id);
      
      //update the available products by add the product back to the unassigned products
      this.availableProducts.push(product);
      // call to remove products from supplier
      await this.supplier.removeProducts([product]);

      this.$emit('success', `Unlinked ${product.name} from supplier`);
    } catch (error) {
      console.error('Error unlinking product:', error);
      this.$emit('error', 'Failed to unlink product from supplier');
    }
  }

  private getChipColor(value: number, trend: 'up' | 'down'): string {
    if (trend === 'up') {
      return value > 10 ? 'error' : 'success';
    }
    return value > 10 ? 'warning' : 'success';
  }
}
