
import { Component, Vue } from 'vue-property-decorator';
import CustomStrategyBuilder from '@/components/strategy/CustomStrategyBuilder.vue';

@Component({
  components: {
    CustomStrategyBuilder
  }
})
export default class CustomStrategyCreate extends Vue {
  private showSnackbar = false;
  private snackbarText = '';
  private snackbarColor = 'success';

  private async handleStrategySave(strategy: any) {
    try {
      // Here you would typically save the strategy to your backend
      console.log('Saving strategy:', strategy);
      
      // Show success message
      this.snackbarText = 'Strategy created successfully';
      this.snackbarColor = 'success';
      this.showSnackbar = true;

      // Navigate back to strategy list after short delay
      setTimeout(() => {
        this.$router.push('/replenishment/strategies');
      }, 1500);
    } catch (error) {
      console.error('Error saving strategy:', error);
      this.snackbarText = 'Error creating strategy';
      this.snackbarColor = 'error';
      this.showSnackbar = true;
    }
  }
}
