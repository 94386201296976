import {get, put} from '@/services/http';
import { SourcingStrategyModel, IProduct } from '@/models/sourcing_strategy';
import { strategyData, strategyMetrics, strategyMetricRules } from '@/mock/strategy-data';
import List from './list';

export class SourcingStrategyList extends List<SourcingStrategyModel> {

  public async fetch() {
    const res: any = await get(`/sourcing/strategies`, {});
    const data = res.data.strategies || [];
    this.mapData(data);
  }

  public mapData(data: any = []) {
    if (!Array.isArray(data)) {
      return;
    }
    for (const s of data) {
      const strategy: SourcingStrategyModel = new SourcingStrategyModel();
      strategy.mapData(s);
      this.add(strategy);
    }
  }

  public async save() {
    try {
      await put(`/sourcing/strategies`, {
        strategies: this.items,
      });
      return true;
    } catch (e) {}
    return false;
  }

  public loadMockStrategies(): void {
    this.items = [];
    
    for (const mockStrategy of strategyData.strategies) {
      const mockProducts = (strategyData.mockProducts as Record<string, IProduct[]>)[mockStrategy.id] || [];
      const strategy = new SourcingStrategyModel({
        ...mockStrategy,
        metrics: strategyMetrics[mockStrategy.id] || [],
        products: mockProducts,
        identificationMetrics: this.createIdentificationMetrics(mockStrategy.id),
        reorderFormula: this.createReorderFormula(mockStrategy.id)
      });
      
      this.add(strategy);
    }
  }

  /**
   * Creates identification metrics for a sourcing strategy by parsing metric rules.
   * 
   * Takes a strategy ID and looks up its metric rules from strategyMetricRules.
   * For each rule, parses the value string to extract:
   * - Operator (<, >, ≥, ≤, =)
   * - Threshold value, handling special formats:
   *   - Dollar amounts (removes $ and commas)
   *   - Percentages (removes %)
   *   - Multipliers (removes x)
   *   - MOQ (sets to 0)
   * 
   * Returns an array of metric objects with:
   * - metric: Lowercase, underscored version of rule label
   * - comparison: Always 'value'
   * - operator: Extracted operator or '>=' default
   * - threshold: Parsed numeric threshold or 0
   * 
   * @param strategyId - ID of the sourcing strategy
   * @returns Array of identification metric objects
   */
  private createIdentificationMetrics(strategyId: string) {
    const rules = strategyMetricRules[strategyId] || [];
    return rules.map(rule => {
      return {
        metric: rule.metric,
        comparison: rule.comparison,
        operator: rule.operator,
        threshold: rule.threshold,
        secondMetric: rule.secondMetric
      };
    });
  }

  private createReorderFormula(strategyId: string) {
    const defaultFormulas: Record<string, {components: any[], formula: string}> = {
      'high-priority': {
        components: [
          { type: 'metric' as const, value: 'lead_time_demand' },
          { type: 'operator' as const, value: '+' },
          { type: 'metric' as const, value: 'safety_stock' },
          { type: 'operator' as const, value: '-' },
          { type: 'metric' as const, value: 'incoming_inventory' }
        ],
        formula: 'lead_time_demand + safety_stock - incoming_inventory'
      },
      'trending': {
        components: [
          { type: 'metric' as const, value: 'avg_daily_demand' },
          { type: 'operator' as const, value: '*' },
          { type: 'metric' as const, value: 'lead_time' },
          { type: 'operator' as const, value: '-' },
          { type: 'metric' as const, value: 'inventory_on_hand' }
        ],
        formula: 'avg_daily_demand * lead_time - inventory_on_hand'
      },
      'seasonal': {
        components: [
          { type: 'metric' as const, value: 'seasonal_index' },
          { type: 'operator' as const, value: '*' },
          { type: 'metric' as const, value: 'historical_sales' },
          { type: 'operator' as const, value: '-' },
          { type: 'metric' as const, value: 'stock_on_hand' }
        ],
        formula: 'seasonal_index * historical_sales - stock_on_hand'
      }
    };

    const formula = defaultFormulas[strategyId];
    if (formula) {
      return {
        type: 'default' as const,
        ...formula
      };
    }

    return {
      type: 'default' as const,
      components: [
        { type: 'metric' as const, value: 'avg_daily_demand' },
        { type: 'operator' as const, value: '*' },
        { type: 'metric' as const, value: 'lead_time' },
        { type: 'operator' as const, value: '-' },
        { type: 'metric' as const, value: 'inventory_on_hand' }
      ],
      formula: 'avg_daily_demand * lead_time - inventory_on_hand'
    };
  }
}

