import { get, post, put, del } from '@/services/http';
import { strategyData, strategyMetrics, strategyMetricRules, strategyReorderFormulas } from '@/mock/strategy-data';

export interface IProduct {
  id: number;
  name: string;
  sku: string;
  currentStock: number;
  recommendedReorderQuantity: number;
  unitCost: number;
  reorderQuantity: number;
}

export interface IStrategyMetric {
  value: string;
  label: string;
  trend?: {
    value: number;
    isPositive: boolean;
  };
}

export interface IIdentificationMetric {
  metric: string;
  comparison: 'value' | 'metric';
  secondMetric?: string;
  operator: string;
  threshold: number | null;
}

export interface IFormulaComponent {
  type: 'metric' | 'operator' | 'value';
  value: string | number;
  label?: string;
}

export interface IReorderFormula {
  type: 'default' | 'custom';
  components: IFormulaComponent[];
  formula: string;
}

interface IStrategyResponse {
  data: {
    metrics?: IStrategyMetric[];
    products?: IProduct[];
    identificationMetrics?: IIdentificationMetric[];
    reorderFormula?: IReorderFormula;
  };
}

interface IStrategiesResponse {
  data: Array<{
    id: string;
    icon: string;
    iconColor: string;
    title: string;
    description: string;
    category: 'demand' | 'supplier' | 'finance' | 'inventory';
    primaryMetric: {
      value: string;
      label: string;
    };
    metrics?: IStrategyMetric[];
    products?: IProduct[];
    identificationMetrics?: IIdentificationMetric[];
    reorderFormula?: IReorderFormula;
  }>;
}

export class SourcingStrategyModel {
  public id: string = '';
  public icon: string = '';
  public iconColor: string = '';
  public title: string = '';
  public description: string = '';
  public category: 'demand' | 'supplier' | 'finance' | 'inventory' = 'demand';
  public primaryMetric: {
    value: string;
    label: string;
  } = { value: '', label: '' };
  public metrics: IStrategyMetric[] = [];
  public products: IProduct[] = [];
  public identificationMetrics: IIdentificationMetric[] = [];
  public reorderFormula: IReorderFormula = {
    type: 'default',
    components: [],
    formula: ''
  };

  constructor(data: any = {}) {
    this.mapData(data);
  }

  public mapData(data: any = {}) {
    this.id = data.id || '';
    this.icon = data.icon || '';
    this.iconColor = data.iconColor || '';
    this.title = data.title || '';
    this.description = data.description || '';
    this.category = data.category || 'demand';
    this.primaryMetric = data.primaryMetric || { value: '', label: '' };
    this.metrics = data.metrics || [];
    this.products = data.products || [];
    this.identificationMetrics = data.identificationMetrics || [];
    this.reorderFormula = data.reorderFormula || {
      type: 'default',
      components: [],
      formula: ''
    };
  }

  public async getStrategyDetails(): Promise<void> {
    try {
      // Find the strategy in mock data
      const mockStrategy = strategyData.strategies.find(s => s.id === this.id);

      if (!mockStrategy) {
        throw new Error(`Strategy with id ${this.id} not found`);
      }
      this.mapData(mockStrategy);
      // Get metrics from mock data
      this.metrics = strategyMetrics[this.id] || [];

      // Get products from mock data
      const mockProducts = (strategyData.mockProducts as Record<string, IProduct[]>)[this.id] || [];
      this.products = mockProducts;

      // Create identification metrics from rules
      this.identificationMetrics = this.createIdentificationMetricsFromRules();

      // Create reorder formula
      this.reorderFormula = this.createReorderFormulaFromTemplate();
    } catch (error) {
      console.error('Error fetching strategy details:', error);
      throw error;
    }
  }

  private createIdentificationMetricsFromRules(): IIdentificationMetric[] {
    const rules = strategyMetricRules[this.id] || [];
    // Rules are already in IIdentificationMetric format, just return them
    return rules;
  }

  private createReorderFormulaFromTemplate(): IReorderFormula {
    const formula = strategyReorderFormulas[this.id];
    if (formula) {
      return formula;
    }

    // Return default formula if no specific formula exists
    return {
      type: 'default',
      components: [
        { type: 'metric', value: 'avg_daily_demand' },
        { type: 'operator', value: '*' },
        { type: 'metric', value: 'lead_time' },
        { type: 'operator', value: '-' },
        { type: 'metric', value: 'inventory_on_hand' }
      ],
      formula: 'avg_daily_demand * lead_time - inventory_on_hand'
    };
  }

  public async updateProducts(products: IProduct[]): Promise<void> {
    try {
      await put(`/api/v1/sourcing/strategies/${this.id}/products`, { products });
      this.products = products;
    } catch (error) {
      console.error('Error updating strategy products:', error);
      throw error;
    }
  }

  public async updateIdentificationMetrics(metrics: IIdentificationMetric[]): Promise<void> {
    try {
      await put(`/api/v1/sourcing/strategies/${this.id}/metrics`, { metrics });
      this.identificationMetrics = metrics;
    } catch (error) {
      console.error('Error updating identification metrics:', error);
      throw error;
    }
  }

  public async updateReorderFormula(formula: IReorderFormula): Promise<void> {
    try {
      await put(`/api/v1/sourcing/strategies/${this.id}/formula`, { formula });
      this.reorderFormula = formula;
    } catch (error) {
      console.error('Error updating reorder formula:', error);
      throw error;
    }
  }

  public getTrendIcon(trend?: { value: number; isPositive: boolean }): string {
    if (!trend) return 'mdi-minus';
    return trend.isPositive ? 'mdi-arrow-up' : 'mdi-arrow-down';
  }

  public getTrendColor(trend?: { value: number; isPositive: boolean }): string {
    if (!trend) return 'grey';
    return trend.isPositive ? 'success' : 'error';
  }

  public getMetricValue(metric: IStrategyMetric): string {
    if (!metric.value) return '0';
    return metric.value;
  }

  public getCategoryIcon(): string {
    const icons = {
      demand: 'mdi-trending-up',
      supplier: 'mdi-truck-delivery',
      finance: 'mdi-currency-usd',
      inventory: 'mdi-package-variant'
    };
    return icons[this.category] || 'mdi-help-circle';
  }

  public getDefaultReorderFormula(): IReorderFormula {
    return {
      type: 'default',
      components: [
        { type: 'operator', value: '(' },
        { type: 'metric', value: 'avg_daily_demand' },
        { type: 'operator', value: '*' },
        { type: 'metric', value: 'lead_time' },
        { type: 'operator', value: ')' },
        { type: 'operator', value: '+' },
        { type: 'metric', value: 'safety_stock' },
        { type: 'operator', value: '-' },
        { type: 'metric', value: 'incoming_inventory' },
        { type: 'operator', value: '-' },
        { type: 'metric', value: 'inventory_on_hand' }
      ],
      formula: '(avg_daily_demand * lead_time) + safety_stock - incoming_inventory - inventory_on_hand'
    };
  }

  public validateIdentificationMetric(metric: IIdentificationMetric): boolean {
    if (!metric.metric || !metric.operator) return false;
    if (metric.comparison === 'value' && metric.threshold === null) return false;
    if (metric.comparison === 'metric' && !metric.secondMetric) return false;
    return true;
  }

  public validateReorderFormula(formula: IReorderFormula): boolean {
    if (!formula.components.length) return false;
    
    let openParens = 0;
    let lastType: string | null = null;
    
    for (const component of formula.components) {
      if (component.type === 'operator') {
        if (component.value === '(') openParens++;
        if (component.value === ')') openParens--;
        if (openParens < 0) return false;
        
        if (['*', '/', '+', '-'].includes(String(component.value))) {
          if (lastType === 'operator') return false;
        }
      } else {
        if (lastType === 'metric' || lastType === 'value') return false;
      }
      
      lastType = component.type;
    }
    
    return openParens === 0 && (lastType === 'metric' || lastType === 'value');
  }

  public static async getStrategies(): Promise<SourcingStrategyModel[]> {
    try {
      const response = await get<IStrategiesResponse>('/api/v1/sourcing/strategies');
      return (response.data || []).map((item: any) => new SourcingStrategyModel(item));
    } catch (error) {
      console.error('Error fetching strategies:', error);
      throw error;
    }
  }
}
