
import { Component, Vue } from 'vue-property-decorator';
import SupplierReplenishment from '@/components/SupplierReplenishment.vue';

@Component({
  components: {
    SupplierReplenishment
  }
})
export default class SupplierReplenishmentView extends Vue {}
