
import { Component, Vue } from 'vue-property-decorator';
import { SourcingStrategyList } from '@/collections/sourcing_strategies';
import { SourcingStrategyModel } from '@/models/sourcing_strategy';

@Component
export default class ProductSourcingStrategy extends Vue {
  private strategies: SourcingStrategyList = new SourcingStrategyList();

  created() {
    // Load mock strategies
    this.strategies.loadMockStrategies();
  }

  private get topStrategies(): SourcingStrategyModel[] {
    // Get only the top 3 priority strategies
    const priorityIds = ['high-priority', 'trending', 'high-value'];
    return this.strategies.items.filter(s => priorityIds.includes(s.id));
  }

  private viewStrategyProducts(strategy: SourcingStrategyModel): void {
    this.$router.push("/replenishment/strategy/" + strategy.id);
  }

  private viewAllStrategies(): void {
    this.$router.push({ 
      name: 'strategyList' 
    }).catch(err => {
      if (err.name !== 'NavigationDuplicated') {
        throw err;
      }
    });
  }
}
