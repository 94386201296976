export interface ISupplierMetric {
  label: string;
  value: number;
  trend: {
    value: number;
    isPositive: boolean;
  };
}

export interface ISupplierRecommendation {
  reason: string;
  priority: 'high' | 'medium' | 'low';
  metrics: {
    lowStockProducts: number;
    outOfStockProducts: number;
    revenueAtRisk: number;
    lastOrderDate: string;
  };
}

export interface IProduct {
  id: string;
  name: string;
  sku: string;
  currentStock: number;
  recommendedReorderQuantity: number;
  unitCost: number;
  product_level_moq: number;
  lead_time: number;
  reorder_frequency: number;
  performance_metrics: {
    turnover_rate: number;
    stock_out_frequency: string;
    demand_variability: 'Low' | 'Medium' | 'High';
  };
}

export interface IBuyingListItem {
  id: string;
  product_id: string;
  quantity: number;
  unit_cost: number;
  total_cost: number;
}

export interface IMetricHistory {
  id: string;
  supplier_id: string;
  metric_type: string;
  metric_value: number;
  trend_direction: 'up' | 'down';
  color_code: 'success' | 'warning' | 'error';
  timestamp: string;
}

// Mock supplier data
export const supplierData = {
  suppliers: [
    {
      id: 1,
      name: 'Global Supply Co.',
      contactEmail: 'contact@globalsupply.com',
      contactPhone: '+1-555-0123',
      address: '123 Supply Chain Rd, CA 94105',
      minOrderValue: 5000,
      maxOrderValue: 50000,
      maxInventoryValue: 200000,
      paymentTerms: 'Net 30',
      leadTime: 14,
      safetyStockPercentage: 20,
      summaryMetrics: [
        {
          label: 'Number of Products',
          value: 25,
          trend: { value: 2, isPositive: true }
        },
        {
          label: 'Average Lead Time',
          value: 14,
          trend: { value: 1, isPositive: false }
        },
        {
          label: 'Active Buying Lists',
          value: 3,
          trend: { value: 1, isPositive: true }
        },
        {
          label: 'Total Inventory Cost',
          value: 125000,
          trend: { value: 5, isPositive: true }
        }
      ],
      recommendation: {
        reason: "High number of low stock items",
        priority: "high" as const,
        metrics: {
          lowStockProducts: 12,
          outOfStockProducts: 3,
          revenueAtRisk: 15000,
          lastOrderDate: "2024-02-15"
        }
      }
    },
    {
      id: 2,
      name: 'Tech Components Ltd',
      contactEmail: 'orders@techcomponents.com',
      contactPhone: '+1-555-0124',
      address: '456 Tech Park, NY 10001',
      minOrderValue: 2000,
      maxOrderValue: 25000,
      maxInventoryValue: 100000,
      paymentTerms: 'Net 45',
      leadTime: 21,
      safetyStockPercentage: 25,
      summaryMetrics: [
        {
          label: 'Number of Products',
          value: 15,
          trend: { value: 1, isPositive: true }
        },
        {
          label: 'Average Lead Time',
          value: 21,
          trend: { value: 2, isPositive: false }
        },
        {
          label: 'Active Buying Lists',
          value: 1,
          trend: { value: 0, isPositive: true }
        },
        {
          label: 'Total Inventory Cost',
          value: 75000,
          trend: { value: 3, isPositive: true }
        }
      ],
      recommendation: {
        reason: "Approaching reorder points",
        priority: "medium" as const,
        metrics: {
          lowStockProducts: 8,
          outOfStockProducts: 1,
          revenueAtRisk: 8000,
          lastOrderDate: "2024-03-01"
        }
      }
    }
  ]
};

// Mock product data by supplier
export const supplierProducts: Record<number, IProduct[]> = {
  1: [
    {
      id: 'prod-001',
      name: 'Premium Widget',
      sku: 'WDG-001',
      currentStock: 150,
      recommendedReorderQuantity: 100,
      unitCost: 25.99,
      product_level_moq: 50,
      lead_time: 10,
      reorder_frequency: 30,
      performance_metrics: {
        turnover_rate: 2.5,
        stock_out_frequency: '0.1%',
        demand_variability: 'Low'
      }
    },
    {
      id: 'prod-002',
      name: 'Standard Widget',
      sku: 'WDG-002',
      currentStock: 300,
      recommendedReorderQuantity: 200,
      unitCost: 15.99,
      product_level_moq: 100,
      lead_time: 14,
      reorder_frequency: 45,
      performance_metrics: {
        turnover_rate: 3.0,
        stock_out_frequency: '0.2%',
        demand_variability: 'Medium'
      }
    }
  ],
  2: [
    {
      id: 'prod-003',
      name: 'Tech Component A',
      sku: 'TCA-001',
      currentStock: 75,
      recommendedReorderQuantity: 50,
      unitCost: 45.99,
      product_level_moq: 25,
      lead_time: 21,
      reorder_frequency: 60,
      performance_metrics: {
        turnover_rate: 1.8,
        stock_out_frequency: '0.5%',
        demand_variability: 'High'
      }
    }
  ]
};

// Types for mock data
interface IBuyingListMock {
  id: string;
  name: string;
  description: string;
  status: 'draft' | 'finalized' | 'completed' | 'canceled';
  supplierId?: number;
  totalValue: number;
  items: IBuyingListItem[];
}

// Mock buying lists
export const buyingListData: IBuyingListMock[] = [
  {
    id: 'bl-001',
    name: 'Weekly Restock - Global Supply',
    description: 'Regular weekly restock order',
    status: 'draft',
    supplierId: 1,
    totalValue: 6500,
    items: [
      {
        id: 'bli-001',
        product_id: 'prod-001',
        quantity: 100,
        unit_cost: 25.99,
        total_cost: 2599
      },
      {
        id: 'bli-002',
        product_id: 'prod-002',
        quantity: 200,
        unit_cost: 15.99,
        total_cost: 3198
      }
    ]
  },
  {
    id: 'bl-002',
    name: 'Monthly Tech Components Order',
    description: 'Regular monthly components order',
    status: 'finalized',
    supplierId: 2,
    totalValue: 2299.50,
    items: [
      {
        id: 'bli-003',
        product_id: 'prod-003',
        quantity: 50,
        unit_cost: 45.99,
        total_cost: 2299.50
      }
    ]
  },
  {
    id: 'bl-003',
    name: 'Q2 Bulk Purchase',
    description: 'Quarterly bulk purchase from multiple suppliers',
    status: 'draft',
    totalValue: 8799.50,
    items: [
      {
        id: 'bli-004',
        product_id: 'prod-001',
        quantity: 150,
        unit_cost: 25.99,
        total_cost: 3898.50
      },
      {
        id: 'bli-005',
        product_id: 'prod-003',
        quantity: 100,
        unit_cost: 45.99,
        total_cost: 4599
      }
    ]
  }
];

// Mock performance metrics history
export const supplierMetricsHistory: Record<number, IMetricHistory[]> = {
  1: [
    {
      id: 'metric-001',
      supplier_id: 'sup-001',
      metric_type: 'order_fill_rate',
      metric_value: 95,
      trend_direction: 'up',
      color_code: 'success',
      timestamp: '2024-01-26T10:00:00Z'
    },
    {
      id: 'metric-002',
      supplier_id: 'sup-001',
      metric_type: 'on_time_delivery',
      metric_value: 98,
      trend_direction: 'up',
      color_code: 'success',
      timestamp: '2024-01-26T10:00:00Z'
    }
  ],
  2: [
    {
      id: 'metric-003',
      supplier_id: 'sup-002',
      metric_type: 'order_fill_rate',
      metric_value: 92,
      trend_direction: 'up',
      color_code: 'warning',
      timestamp: '2024-01-26T10:00:00Z'
    },
    {
      id: 'metric-004',
      supplier_id: 'sup-002',
      metric_type: 'on_time_delivery',
      metric_value: 94,
      trend_direction: 'down',
      color_code: 'error',
      timestamp: '2024-01-26T10:00:00Z'
    }
  ]
};

// Helper function to get mock data
export const getMockSupplierData = (supplierId: number) => {
  return {
    supplier: supplierData.suppliers.find(s => s.id === supplierId),
    products: supplierProducts[supplierId] || [],
    buyingLists: buyingListData.filter(list => list.supplierId === supplierId),
    metricsHistory: supplierMetricsHistory[supplierId] || []
  };
};

// Helper function to get all buying lists
export const getAllBuyingLists = () => {
  return buyingListData;
};

// Add unassigned products
export const unassignedProducts = [
  {
    id: 'p-u-001',
    name: 'Smart Watch Pro',
    sku: 'SWP-001',
    currentStock: 15,
    recommendedReorderQuantity: 20,
    unitCost: 89.99,
    performance_metrics: {
      turnover_rate: 2.5,
      demand_variability: 'Medium'
    }
  },
  {
    id: 'p-u-002',
    name: 'Wireless Earbuds Plus',
    sku: 'WEP-002',
    currentStock: 8,
    recommendedReorderQuantity: 25,
    unitCost: 45.99,
    performance_metrics: {
      turnover_rate: 3.2,
      demand_variability: 'High'
    }
  },
  {
    id: 'p-u-003',
    name: 'Ultra HD Camera',
    sku: 'UHC-003',
    currentStock: 12,
    recommendedReorderQuantity: 15,
    unitCost: 299.99,
    performance_metrics: {
      turnover_rate: 1.8,
      demand_variability: 'Low'
    }
  },
  {
    id: 'p-u-004',
    name: 'Gaming Mouse Elite',
    sku: 'GME-004',
    currentStock: 20,
    recommendedReorderQuantity: 30,
    unitCost: 59.99,
    performance_metrics: {
      turnover_rate: 2.9,
      demand_variability: 'Medium'
    }
  },
  {
    id: 'p-u-005',
    name: 'Mechanical Keyboard RGB',
    sku: 'MKR-005',
    currentStock: 18,
    recommendedReorderQuantity: 25,
    unitCost: 129.99,
    performance_metrics: {
      turnover_rate: 2.1,
      demand_variability: 'Medium'
    }
  }
];

// Helper function to get all products that are not assigned to any supplier
export function getUnassignedProducts(): any[] {
  const assignedProductIds = new Set();
  
  // Collect all assigned product IDs
  Object.values(supplierProducts).forEach(products => {
    products.forEach(product => {
      assignedProductIds.add(product.id);
    });
  });
  
  // Return products that are not in the assigned set
  return unassignedProducts.filter(product => !assignedProductIds.has(product.id));
} 