
import { Component, Vue, Prop } from 'vue-property-decorator';

interface ISystem {
  id: string;
  name: string;
  icon: string;
  description: string;
  connected: boolean;
  disabled: boolean;
  maxOrderValue?: number;
}

@Component
export default class ConvertToPODialog extends Vue {
  @Prop({ type: Boolean, default: false })
  private readonly value!: boolean;

  private showConnectionDialog: boolean = false;
  private isConnecting: boolean = false;
  private selectedSystem: ISystem | null = null;

  private systems = [
    {
      id: 'google-sheets',
      name: 'Google Sheets',
      icon: '/icons/google-sheets.png',
      description: 'Create a new PO in Google Sheets',
      connected: true,
      disabled: false,
      maxOrderValue: 50000,
    },
    {
      id: 'quickbooks',
      name: 'QuickBooks',
      icon: '/icons/quickbooks.png',
      description: 'Create a purchase order in QuickBooks',
      connected: false,
      disabled: false,
      maxOrderValue: 100000,
    },
    {
      id: 'shopify',
      name: 'Shopify',
      icon: '/icons/shopify.png',
      description: 'Create a PO using Shopify\'s PO system',
      connected: false,
      disabled: false,
      maxOrderValue: 75000,
    },
  ];

  public get show(): boolean {
    return this.value;
  }

  public set show(value: boolean) {
    this.$emit('input', value);
  }

  private selectSystem(system: ISystem): void {
    if (system.disabled) {
      return;
    }
    
    if (system.connected) {
      this.$emit('convert', { system: system.id });
      this.closeDialog();
    } else {
      this.selectedSystem = system;
      this.showConnectionDialog = true;
    }
  }

  private async connectSystem(system: ISystem): Promise<void> {
    this.selectedSystem = system;
    this.showConnectionDialog = true;
  }

  private async authorizeConnection(): Promise<void> {
    if (!this.selectedSystem) {
      return;
    }

    this.isConnecting = true;
    try {
      // Mock API call for connection
      await new Promise((resolve) => setTimeout(resolve, 1500));
      
      // Update local state
      this.systems = this.systems.map((s) => 
        s.id === this.selectedSystem?.id 
          ? { ...s, connected: true }
          : s
      );

      this.showConnectionDialog = false;
      
      // Proceed with conversion
      this.$emit('convert', { system: this.selectedSystem.id });
      this.closeDialog();
    } finally {
      this.isConnecting = false;
    }
  }

  private closeDialog(): void {
    this.show = false;
    this.showConnectionDialog = false;
    this.selectedSystem = null;
  }
}
