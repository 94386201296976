import { put } from '@/services/http';

export interface ISupplierSettings {
  leadTime: number;
  fixOrderQuantity: number;
  minOrderQuantity: number;
  maxOrderQuantity: number;
  orderCycle: number;
  supplierId: number;
}

export class SupplierSettings implements ISupplierSettings {
  public leadTime: number = 0;
  public fixOrderQuantity: number = 0;
  public minOrderQuantity: number = 0;
  public maxOrderQuantity: number = 0;
  public orderCycle: number = 0;
  public supplierId: number = 0;

  constructor(data: Partial<ISupplierSettings> = {}) {
    this.leadTime = data.leadTime || 0;
    this.fixOrderQuantity = data.fixOrderQuantity || 0;
    this.minOrderQuantity = data.minOrderQuantity || 0;
    this.maxOrderQuantity = data.maxOrderQuantity || 0;
    this.orderCycle = data.orderCycle || 0;
    this.supplierId = data.supplierId || 0;
  }

  public async save(): Promise<void> {
    const data: Record<string, number> = {
      supplier_id: this.supplierId,
      leadtime: this.leadTime,
      fix_order_quantity: this.fixOrderQuantity,
      min_order_quantity: this.minOrderQuantity,
      max_order_quantity: this.maxOrderQuantity,
      order_cycle: this.orderCycle,
    };
    
    return await put('/supplier_settings', data);
  }

  public canSave(): boolean {
    return this.supplierId > 0;
  }
} 