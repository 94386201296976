
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SourcingStrategyModel } from '@/models/sourcing_strategy';
import { IProduct } from '@/models/sourcing_strategy';

interface ITableHeader {
  text: string;
  value: string;
  sortable?: boolean;
}

@Component
export default class StrategyDetailComponent extends Vue {
  @Prop({ required: true }) readonly strategyId!: string;

  private strategy: SourcingStrategyModel | null = null;
  private isLoading = true;
  private localProducts: IProduct[] = [];

  private headers: ITableHeader[] = [
    { text: 'Product Name', value: 'name' },
    { text: 'SKU', value: 'sku' },
    { text: 'Current Stock', value: 'currentStock' },
    { text: 'Recommended Reorder Quantity', value: 'recommendedReorderQuantity' },
    { text: 'Unit Cost', value: 'unitCost' },
    { text: 'Reorder Quantity', value: 'reorderQuantity' }
  ];

  get metrics() {
    if (!this.strategy?.metrics) return [];
    return this.strategy.metrics.map(metric => ({
      value: metric.value,
      label: metric.label,
      trend: metric.trend || { value: 0, isPositive: true }
    }));
  }

  async created() {
    try {
      this.strategy = new SourcingStrategyModel();
      this.strategy.id = this.strategyId;
      await this.strategy.getStrategyDetails();
      this.localProducts = [...(this.strategy.products || [])];
    } catch (error) {
      console.error('Error loading strategy:', error);
    } finally {
      this.isLoading = false;
    }
  }

  private selectAllProducts(): void {
    this.localProducts = this.localProducts.map(p => ({
      ...p,
      reorderQuantity: p.recommendedReorderQuantity
    }));
    this.$emit('update', this.localProducts);
  }

  private goBack(): void {
    this.$router.back();
  }

  get hasSelectedProducts(): boolean {
    return this.localProducts.some(p => p.reorderQuantity > 0);
  }

  private proceedToBuyingList(): void {
    if (!this.strategy) return;
    
    this.$router.push({
      name: 'buyingListDetail',
      params: {
        products: JSON.stringify(this.localProducts.filter(p => p.reorderQuantity > 0)),
        supplier: JSON.stringify({
          id: 'default',
          name: this.strategy.title,
          moq: 0,
          minOrderValue: 0
        })
      }
    }).catch(err => {
      if (err.name !== 'NavigationDuplicated') {
        throw err;
      }
    });
  }
}
