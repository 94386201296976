
import { Component, Vue } from 'vue-property-decorator';
import { strategyCategories } from '@/mock/strategy-data';
import { SourcingStrategyList } from '@/collections/sourcing_strategies';
import { SourcingStrategyModel } from '@/models/sourcing_strategy';

@Component
export default class StrategyList extends Vue {
  private strategies: SourcingStrategyList = new SourcingStrategyList();
  private strategyCategories = strategyCategories;
  private loading: boolean = true;

  async created() {
    try {
      // Load mock strategies - this already includes all necessary data
      this.strategies.loadMockStrategies();
    } catch (error) {
      console.error('Error loading strategies:', error);
    } finally {
      this.loading = false;
    }
  }

  private getStrategiesByCategory(category: string): SourcingStrategyModel[] {
    return this.strategies.items.filter(strategy => strategy.category === category);
  }

  private viewStrategy(strategy: SourcingStrategyModel): void {
    this.$router.push({
      name: 'strategyDetail',
      params: { strategyId: strategy.id }
    });
  }
}
